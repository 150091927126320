<app-header></app-header>


<div class="course-packages">
  <div fxLayout="row wrap">
    <div class="img-box">
      <img src="../../../../../../../assets/img/girl_with_book.png" alt="girl_with_book">
    </div>
    <div class="right-text">
      <h2>High Impact Training Materials <br>
        with all you need to deliver a <br>
        powerful training</h2>
    </div>
  </div>
</div>

<div class="select-course-package">
  <div class="heading">
    <h2>Select the course packages</h2>
  </div>
  <div fxLayout="row wrap" fxLayoutGap="31px" fxLayoutAlign="center none">
    <div class="course-card" fxFlex.gt-sm="25" fxFlex="100" *ngFor="let item of activeSubscriptionList">
      <div class="card">
        <div class="card-header">
          <div class="img-box">
            <div class="text-over">
              <span>{{item.name}}</span>
              <div class="download" (click)="download(item.id)">
                <span *ngIf="item.name === '100 management / behavioral / functional courses'">
                  Click to download the course list
                </span>
                <span *ngIf="item.name === 'Book Summaries'">
                  Click to download the book list
                </span>
                <span *ngIf="item.name === 'Spoken English Course'">
                  Click to download the Course details
                </span>
              </div>
            </div>
            <img src="{{item.banner}}" alt="course">
          </div>
          <div class="coming-soon" *ngIf="item.name === 'Book Summaries'">
            <img src="../../../../../../../assets/img/coming-soon.png" alt="">
          </div>
        </div>
        <div class="card-body">
          <div class="text-desc">
            <!-- <span>Access 100 management /</span>
            <span>behavioral /</span>
            <span>functional courses</span> -->
            <span>{{item.currency}} {{item.price}} for {{item.validity}}</span>
          </div>
        </div>
        <div class="card-footer">
          <div class="m-b-20 m-t-15 btn-group">
            <button mat-flat-button class="add-to-cart" (click)="addToCart(item)">Add to cart</button>
            <button mat-flat-button class="buy-now" (click)="goToCart(item)">Buy now</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<footer></footer>