<app-header></app-header>
<div class="custom-courses-packages">
    <div class="heading">
        <h2>Pleased to meet you </h2>
        <h3>We’d like to get to know you better - please tell us a little about yourself</h3>
    </div>
    <form [formGroup]="form">
        <div fxLayout="row" fxLayoutAlign="center center">
            <div fxFlex.gt-sm="45" fxFlex="100">
                <div class="mt-5 ">
                    <div class="input-hint">Email address <span class="text-danger">*</span> </div>
                    <mat-form-field appearance="outline">
                        <input matInput formControlName="email" pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}">
                          <small *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched" 
                          class="text-danger support-text">{{validationMessage.emailRequired}} </small>
                          <small *ngIf="form.controls['email'].hasError('pattern') && form.controls['email'].touched" 
                          class="text-danger support-text">{{validationMessage.emailRequired}} </small>
                    </mat-form-field>
                    <div class="input-hint">First name <span class="text-danger">*</span></div>
                    <mat-form-field appearance="outline">
                        <input matInput formControlName="firstName">
                <small *ngIf="form.controls['firstName'].hasError('required') && form.controls['firstName'].touched"
                  class="text-danger support-text">{{validationMessage.firstNameRequired}}</small>
                    </mat-form-field>

                    <div class="input-hint">Last name <span class="text-danger">*</span></div>
                    <mat-form-field appearance="outline">
                        <input matInput formControlName="lastName">
                        <small *ngIf="form.controls['lastName'].hasError('required') && form.controls['lastName'].touched"
                          class="text-danger support-text">{{validationMessage.lastNameRequired}}</small>
                    </mat-form-field>

                    <div class="input-hint">Phone number <span class="text-danger">*</span></div>
                    <mat-form-field appearance="outline">
                        <input matInput type="number" formControlName="phone">
                <small *ngIf="form.controls['phone'].hasError('required') && form.controls['phone'].touched"
                  class="text-danger support-text">{{validationMessage.phone}}</small>
                  <small *ngIf="form.controls['phone'].hasError('pattern')"
                  class="text-danger support-text">{{validationMessage.phone}}</small>
                    </mat-form-field>

                    <div class="input-hint">Organization name <span class="text-danger">*</span></div>
                    <mat-form-field appearance="outline">
                        <input matInput formControlName="organizationName">
                        <small *ngIf="form.controls['organizationName'].hasError('required') && form.controls['organizationName'].touched"
                          class="text-danger support-text">{{validationMessage.organizationNameRequired}}</small>
                            
                    </mat-form-field>
                    <div class="btn-bottom">
                        <button mat-flat-button class="btn-confirm" (click)="submit()">Confirm</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<app-footer></app-footer>