
<div class="login-register" style="background-image:url(assets/images/background/login-register.jpg);">
    <div class="login-register-box">
      <mat-card>
        <mat-card-content *ngIf="step1">
          <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="text-center">
              <img alt="homepage" src="assets/img/logo.png" width="150" class="m-b-20">
              <h4 class="m-t-0">Reset Password (Step 1)</h4>
            </div>
            <p class="text-center font-14">Enter your email and we'll send you the password reset link.</p>
            <div class="overlay" *ngIf="loader">
+        <mat-spinner></mat-spinner>
+    </div>
            <div fxLayout="column" fxLayoutAlign="space-around">
              <div class="pb-1">
                <mat-form-field style="width: 100%">
                  <input matInput placeholder="Email address" type="email" [formControl]="form.controls['email']">
                </mat-form-field>
                <small *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched" class="text-danger support-text">{{validationMessage.emailRequired}}</small>
                <small *ngIf="form.controls['email'].errors?.email && form.controls['email'].touched" class="text-danger support-text">{{validationMessage.emailRequired}}</small>
              </div>
              <button mat-raised-button color="primary" type="submit" class="btn-block btn-lg m-t-20" [disabled]="!form.valid">Submit</button>
            </div>
  
          </form>
        </mat-card-content>
        <mat-card-content *ngIf="step2">
          <div class="text-center">
            <img alt="homepage" src="assets/images/logo-icon.png">
            <h4 class="m-t-0">Reset Password (Step 2)</h4>
          </div>
          <div class="text-center">
            <p>An e-mail has been sent to {{this.form.value.email}} with instructions for resetting you password. Please click the link in the e-mail to verify your e-mail address.
            </p>
            <p>If you do not receive an e-mail from us, please ensure that your spam filter has not blocked it. If you still have not received an e-mail within 1 hour, please contact customer support <a href="" style="text-decoration: underline;">here.</a></p>
            <p>After you reset your password, you may <a href="/public/login" style="text-decoration: underline;">click here to continue</a>.</p>
        </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>


