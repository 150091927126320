import {
    ChangeDetectorRef,
    Component,
    NgZone,
    OnDestroy,
    ViewChild,
    HostListener,
    Directive,
    AfterViewInit
} from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from '../../../core/services/token.service';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MediaMatcher } from '@angular/cdk/layout';


import { MenuItems } from '../../../shared/menu-items/menu-items';
@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: []
})
export class AppSidebarComponent implements OnDestroy {
    userRole:any;                               // to get the role of user
    public config: PerfectScrollbarConfigInterface = {};
    mobileQuery: MediaQueryList;

    private _mobileQueryListener: () => void;
    status = true;

    itemSelect: number[] = [];
    parentIndex: Number;
    childIndex: Number;

    setClickedRow(i, j) {
        this.parentIndex = i;
        this.childIndex = j;
    }
    subclickEvent() {
        this.status = true;
    }
    scrollToTop() {
        document.querySelector('.page-wrapper').scroll({
            top: 0,
            left: 0
        });
    }

    constructor(
        changeDetectorRef: ChangeDetectorRef,
        media: MediaMatcher,
        public menuItems: MenuItems,
        public router: Router,
        public tokenService: TokenService
    ) {
        this.userRole= this.tokenService.isRole();
        this.mobileQuery = media.matchMedia('(min-width: 768px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mobileQuery.addListener(this._mobileQueryListener);

        $('.close-sidenav').click(()=>{
           
        });
    }

  /**
  *  This function routes to people route,
  * @method goTo
  * @param link particular link.
  */
    goTo(link) {
        console.log(link)
        if (link == 'group-admin/people') {
            console.log('/' + link + '/' + this.tokenService.getGroupId())
            this.router.navigate(['/' + link + '/' + this.tokenService.getGroupId() ]);
        } 
        else if (link == 'group-admin/leader-board' || link == 'user/leader-board') {
            
            if(this.tokenService.isAllowedLeaderboard() == 1) {
                this.router.navigate(['/' + link + '/' + this.tokenService.getGroupId() ]);
            }
            else {
                return;
            }
        } 
        else if (link == 'group-admin/internal/courses') {
            if(this.tokenService.isAllowedGroupAdmin() == 1) {
                this.router.navigate(['/' + link]);
            }
            else {
                return;
            }
           
        } 
        else {
            console.log(link)
            this.router.navigate(['/' + link]);
        }
    }

    ngOnDestroy(): void {
        this.mobileQuery.removeListener(this._mobileQueryListener);
    }

  /**
  *  This function puts active class to the header,
  * @method getActiveClass
  * @param menuName menu name.
  */
    getActiveClass(menuName: string) {
        menuName = menuName.toLowerCase();

        if (menuName === 'internal courses') {
            menuName = 'internal';
        }
        if ((this.router.url).includes(menuName)) {
            return 'active';
        } else {
            return '';
        }
    }
}
