import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenService } from '../../../core/services/token.service';
import { environment } from '../../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class GroupAdminService {

  apiToken: any;
  constructor(private http: HttpClient,
    private tokenService: TokenService) {
  }


  getListOfCourseType() {
    return this.http.get('course_type?userId=' + this.tokenService.getUserId() + '&groupId=' + this.tokenService.getGroupId());
  }

  getListOfCourseCategory() {
    return this.http.get('course_category?userId=' + this.tokenService.getUserId() + '&groupId=' + this.tokenService.getGroupId());
  }

  updateBulkUsersToGroup(groupId, groupData) {
    return this.http.put('group_users_bulk?userId=' + this.tokenService.getUserId() + '&groupId=' + groupId, groupData);
  }

  downloadBulkUsersInGroup(groupId) {
    return this.http.get('download_users?userId=' + this.tokenService.getUserId() + '&groupId=' + groupId);
  }

  downloadCertificate(id) {
    return this.http.get('download_certificate_url/'+ id);
  }

  updateMailSettings(data: any, frequencyId: number) {
    return this.http.post('updateStateGroupFrequencyData/'+ frequencyId +'?userId='+this.tokenService.getUserId() + '&groupId=' + this.tokenService.getGroupId() ,data);
  }

  updateMailSettingsStatus(data: any) {
    console.log(data);
    return this.http.post('updateStatusGroup?userId='+this.tokenService.getUserId() + '&groupId=' + this.tokenService.getGroupId() ,data);
  }

  getMailSettings() {
    return this.http.get('getGroupFrequencyData?userId='+this.tokenService.getUserId() + '&groupId=' + this.tokenService.getGroupId());
  }

  updateProfile(data) {
    return this.http.put(`users?&userId=${this.tokenService.getUserId()}&fields=basic`, data);
  }

  updatePassword(data) {
    return this.http.post('updatePassword?userId=' + this.tokenService.getUserId(), data);
  }

  getUserProfileDetails() {
    return this.http.get('users/' + this.tokenService.getUserId() + '?userId=' + this.tokenService.getUserId());
  }

  getListOfCoursesInGroup(page, orderByField, orderByKeyword, name, isDeleted, groupId, limit) {
    return this.http.get('my_courses?page=' + page + '&userId=' +
      this.tokenService.getUserId() + '&orderByField=' + orderByField +
      '&orderByKeyword=' + orderByKeyword + '&name=' + name + '&isDeleted=' + isDeleted + '&groupId=' + groupId + '&limit=' + limit);
  }

  getListOfCourseByGroupAdmin(page, orderByField, orderByKeyword, name, isDeleted, groupId,limit) {
    return this.http.get('group_courses?page=' + page + '&userId=' +
      this.tokenService.getUserId() + '&orderByField=' + orderByField +
      '&orderByKeyword=' + orderByKeyword + '&name=' + name + '&isDeleted=' + isDeleted + '&groupId=' + groupId+'&limit=' + limit);
  }

  createGroupAdminCourse(courseData) {
    return this.http.post('group_courses?userId=' + this.tokenService.getUserId() +
     '&groupId=' + this.tokenService.getGroupId(), courseData);
  }

  getGroupAdminCourseDetails(courseId) {
    return this.http.get('group_courses/' + courseId + '?userId=' +
    this.tokenService.getUserId() + '&groupId=' + this.tokenService.getGroupId());
  }

  editGroupAdminCourse(courseId, data) {
    return this.http.put('group_courses/' + courseId + '?fields=basic' + '&userId=' + this.tokenService.getUserId()
    + '&groupId=' + this.tokenService.getGroupId(), data);
  }

  deleteGroupAdminCourse(courseId) {
    return this.http.delete('group_courses/' + courseId + '?userId='
     + this.tokenService.getUserId() + '&groupId=' + this.tokenService.getGroupId());
  }

  DeactivateActivateCourseByGroupAdmin(courseId, data) {
    return this.http.put('group_courses/' + courseId + '?fields=isActive' +
     '&userId=' + this.tokenService.getUserId() + '&groupId=' + this.tokenService.getGroupId(), data);
  }

  DeactivateActivateUser(userId, data) {
    return this.http.put('group_users/' + userId +
     '?fields=isActive' + '&userId=' + this.tokenService.getUserId() + '&groupId=' + this.tokenService.getGroupId(), data);
  }

  DeactivateInternalCoursesActivateUser(userId, data) {
    return this.http.put('group_course_enrollments/' + userId +
     '?fields=isActive' + '&userId=' + this.tokenService.getUserId() + '&groupId=' + this.tokenService.getGroupId(), data);
  }

  editGroupAdminUsers(userId, data) {
    return this.http.put('group_users/' + userId +
     '?fields=updateAll' + '&userId=' + this.tokenService.getUserId() + '&groupId=' + this.tokenService.getGroupId(), data);
  }

  createUser(data) {
    return this.http.post('group_users?userId=' + this.tokenService.getUserId() + '&groupId=' + this.tokenService.getGroupId(), data);
  }

  getUserDetail(userId) {
    return this.http.get('group_users/' + userId +
     '?userId=' + this.tokenService.getUserId() + '&groupId=' + this.tokenService.getGroupId());
  }

  getDropdownForLeaderBoard(getByValue) {
    return this.http.get('dropdownForLeaderBoard?getByValue=' + getByValue + 
     '&userId=' + this.tokenService.getUserId() + '&groupId=' + this.tokenService.getGroupId());
  }

  getListOfUsers(page, orderByField, orderByKeyword, name, isDeleted, groupId,limit) {
    return this.http.get('group_users?page=' + page + '&userId=' + this.tokenService.getUserId() + '&orderByField=' + orderByField +
      '&orderByKeyword=' + orderByKeyword + '&name=' + name + '&isDeleted=' + isDeleted + '&groupId=' + groupId+'&limit=' + limit);
  }
  getListOfInternalUsers(page, orderByField, orderByKeyword, name, isDeleted, courseId,limit) {
    return this.http.get('group_course_enrollments?page=' +
     page + '&userId=' + this.tokenService.getUserId() + '&name=' + name + '&courseId=' + courseId
    + '&groupId=' + this.tokenService.getGroupId()+'&limit=' + limit);
  }

  getListOfCourseToGroupAdmin(page, orderByField, orderByKeyword, name, isDeleted, courseId, limit) {
    return this.http.get('my_courses?page=' + page + '&userId=' +
      this.tokenService.getUserId() + '&orderByField=' + orderByField +
      '&orderByKeyword=' + orderByKeyword + '&name=' + name + '&isDeleted='
       + isDeleted + '&courseId=' + courseId  + '&groupId=' + this.tokenService.getGroupId() + '&limit=' + limit);
  }
  updateAppearanceSettings(groupId, data) {
    return this.http.put(`groups/${groupId}?userId=${this.tokenService.getUserId()}&fields=appearanceSettings&groupId=${groupId}`, data);
  }

  getAppearanceSettings(domain) {
    console.log(domain);
    return this.http.get('appearanceSettings?domain=' + domain);
  }

  getGroupDetail(groupId) {
    return this.http.get('groups/' + groupId + '?userId=' + this.tokenService.getUserId() + '&groupId=' + groupId);
  }

  getLeaderBoardDetail(groupId, region: any, funct: any, managerId: any) {
    return this.http.get('group_users_rank?userId=' + this.tokenService.getUserId() + '&groupId=' + groupId
    + '&region=' + region + '&function=' + funct + '&managerEmailId=' + managerId);
  }

  getMyRankDetail(groupId) {
    return this.http.get('my_rank?userId=' + this.tokenService.getUserId() + '&groupId=' + groupId);
  }

  assignCoursesToUsers(data) {
    return this.http.post('group_course_enrollments?userId=' +
     this.tokenService.getUserId() + '&groupId=' + this.tokenService.getGroupId(), data);
  }

  deleteUser(id) {
    return this.http.delete('group_course_enrollments/' +
     id + '?userId=' + this.tokenService.getUserId() + '&groupId=' + this.tokenService.getGroupId());
  }

  activeUserInCourses(id) {
    return this.http.get('active_group_course_enrollments?userId=' +
     this.tokenService.getUserId() + '&courseId=' + id + '&groupId=' + this.tokenService.getGroupId());
  }

  //new Apis:

  getListOfFoldersOfGroup(groupId) {
    return this.http.get('enrollment_group_folders?userId='+ this.tokenService.getUserId()+'&groupId=' + groupId);
  }
  
  getListOfFolderCourses(folderId,courseIds) {
    return this.http.get('enrollment_folder_courses?userId='+ this.tokenService.getUserId()+'&folderId='+folderId + '&courseIds=' + courseIds);
  }

  getListOfUsersOfGroup(folderId,groupId,courseId,groupUserIds) {
    return this.http.get('enrollment_group_users?userId='+ this.tokenService.getUserId()+'&folderId=' + folderId + '&groupId=' + groupId + '&courseId=' + courseId + '&groupUserIds='+ groupUserIds);
  }

  changeCourseEnrollmentExpiry(userId,groupId,data) {
    return this.http.put('course_enrollments/' + userId + '?userId=' + this.tokenService.getUserId() + '&groupId=' + groupId,data);
  }

  enrollUsersInCourse(data) {
    return this.http.post('course_enrollments?userId=' + this.tokenService.getUserId(),data );
  }

  getListOfCoursesOfGroup(groupId) {
    return this.http.get('enrollment_group_courses?userId='+ this.tokenService.getUserId()+'&groupId=' + groupId);
  }

  getListOfEnrolledUsersOfCourse(groupId,courseId,page, orderByField, orderByKeyword, studentName,folderId,limit) {
    return this.http.get('course_enrollments?userId=' + this.tokenService.getUserId() + '&groupId=' + groupId + '&courseId=' + courseId+ '&page=' + page  + '&orderByField=' + orderByField +
      '&orderByKeyword=' + orderByKeyword + '&studentName=' + studentName + '&folderId=' + folderId +'&limit=' + limit);
  }

  deleteEnrolledUsers(enrollmentIds,groupId) {
    return this.http.put('remove_bulk_course_enrollments' + '?userId=' + this.tokenService.getUserId() + '&groupId=' + groupId,enrollmentIds)
  }

  addUsersToGroup(groupId, groupData) {
    return this.http.post('group_users_bulk?userId=' + this.tokenService.getUserId() + '&groupId=' + groupId, groupData);
  }

  bulkCourseUpload(groupId, courseData) {
    return this.http.post('bulk_csv_course_assignments?userId=' + this.tokenService.getUserId() + '&groupId=' + groupId, courseData);
  } 

  groupCoursePaginated(groupId,page,limit) {
    return this.http.get('enrollment_group_courses_paginated?groupId=' + groupId + '&userId=' + this.tokenService.getUserId()+'&page='+ page+'&limit='+limit )
  }

  internalCourseAssignment(groupId,data) {
    return this.http.post('group_course_enrollments?userId=' + this.tokenService.getUserId()+ '&groupId=' + groupId,data)
  }

  createFeedbackQuestion(data) {
    return this.http.post('add_feedback_settings',data)
  }

  updateFeedbackQuestion(questionId,data) {
    return this.http.put('group_feedback_settings/'+ questionId,data)
  }

  changeFeedbackQuestionStatus(questionId,data) {
    return this.http.put('status_group_feedback_settings/'+ questionId,data)
  }

  getFeedbackQuestionList() {
    return this.http.get('all_feedback_settings?userId=' + this.tokenService.getUserId() + '&groupId=' + this.tokenService.getGroupId())
  }

  getFeedbackQuestionDetail(id) {
    return this.http.get('group_feedback_settings/'+ id  + '?userId=' + this.tokenService.getUserId() + '&groupId=' + this.tokenService.getGroupId())
  }

  getUserFeedbackQuestionAnswers(pageNo, search) {
    return this.http.get('user_feedback_entries?userId=' + this.tokenService.getUserId() + '&groupId=' + this.tokenService.getGroupId()
    + '&page=' + pageNo + '&search=' + search)
  }

  getUserQuestions() {
    return this.http.get('feedback_form?userId=' + this.tokenService.getUserId() + '&groupId=' + this.tokenService.getGroupId())
  }

  submitFeedbackForm(data) {
    return this.http.post('feedback_entries',data)
  }

  changeBasicScoreStatus(data) {
    return this.http.post('group_users_score',data)
  }

  getBasicScoreStatus() {
    return this.http.get('group_users_score?userId=' + this.tokenService.getUserId() + '&groupId=' + this.tokenService.getGroupId()
    + '&companyId=' + this.tokenService.getCompanyId())
  }
}
