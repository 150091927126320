
<app-header></app-header>

<div class="container jumbotron" style="margin-top: 120px;">
    <div class="custom-text_out">
        <p class="intro">These terms and conditions ("Terms and Conditions") govern your use of this website
          www.onehourlearning.com ("Website"). In these Terms and Conditions, Bensar Learning Pvt. Ltd., the owner of this
          website www.onehourlearning.com is referred to as the "Company", "OneHourLearnining"," "us," or "we."</p>
        <p><i>" By using this Website and its services you will be bound by these Terms and Conditions and the Company's
            privacy policy which can be found at http://www.onehourlearning.com/privacy ("Privacy Policy"). If you do not
            agree to these Terms and Conditions and/or the Privacy Policy you must not use this Website or any service
            provided via it. Use of the Company's Website and Services (as defined below) is expressly conditioned upon your
            assent to all the terms and conditions of this agreement, to the exclusion of all other terms."</i></p>
        <h4>1. Introduction</h4>
        <p>a) Welcome to OneHourLearning. These terms and conditions apply to the services available from and related to the
          domain and sub-domains of the Website (together with the Website, the "Services"). b) For as long as the Company
          continues to offer the Services, it shall provide and seek to update, improve and expand the Services. As a result,
          the Company allows you to access the Website as it may exist and be available on any day and it has no other
          obligations, except as expressly stated in these Terms and Conditions. The Company may modify, replace, refuse
          access to, suspend or discontinue the Website, partially or entirely, or change and modify prices for all or part of
          the Services in our sole discretion. All of these changes shall be effective upon their posting on our site or by
          direct communication to you unless otherwise noted. Your use of the Services following such posting or communication
          constitutes your acceptance of the terms and conditions of this Agreement as modified. c) Nothing in these Terms and
          Conditions is intended to, or shall be deemed to, establish any partnership or joint venture between any of the
          parties, constitute any party the agent of another party, nor authorize any party to make or enter into any
          commitments for or on behalf of any other party.</p>
        <h4>2. Your Use of this Website</h4>
        <p>a) You must not use this Website for anything that is unlawful or is prohibited by these Terms and Conditions
          and/or any notices elsewhere on this Website. b) The Company advises that you seek professional advice before
          relying on any information on this Website. Under no circumstances will Company be liable in any way for any
          information it provides on the Website or through the Services, including, but not limited to, any errors or
          omissions in any content and information, including but not limited to text, software, music, sound, photographs,
          graphics, video or other material (also known as "Content"), or any loss or damage of any kind incurred in
          connection with use of or exposure to any Content posted, emailed, accessed, transmitted, or otherwise made
          available via the Services. c) When using this Website, you must not do any of the following: i. defame, abuse,
          harass, stalk, threaten or otherwise violate the rights (such as rights of privacy and publicity) of others; ii.
          publish, post, distribute or disseminate any defamatory, infringing, obscene, indecent, offensive or unlawful
          material or information; iii. upload files that contain software or other material protected by intellectual
          property laws (or by rights of privacy of publicity) unless you own or control the rights or have received all
          necessary consents; iv. upload files that contain viruses, corrupted files or any other similar software or programs
          that may damage the operation of another's computer; v. impersonate any person or entity, including without
          limitation any employee or representative of Company; vi. post or transmit, or cause to be posted or transmitted,
          any communication or solicitation designed or intended to obtain password, account, or private information from any
          other user of the Services; vii. run Maillist, Listserv, any form of auto-responder, or "spam" on the Services, or
          any processes that run or are activated while you are not logged on to the Website, or that otherwise interfere with
          the proper working of or place an unreasonable load on the Services' infrastructure; viii. decompile, reverse
          engineer, or otherwise attempt to obtain the source code of the Services; ix. delete any author attributions, legal
          notices or proprietary designations or labels in any file that is uploaded; x. falsify the origin or source of
          software or other material contained in a file that is uploaded; xi. advertise or offer to sell any goods or
          services or conduct or forward surveys, competitions, or chain letters; or xii. download any file posted by another
          user of this Website that you know, or reasonably should know, cannot be legally distributed in such manner. d) You
          will be responsible for withholding, filing, and reporting all taxes, duties and other governmental assessments
          associated with your activity in connection with the Services. e) You represent and warrant to Company that you are
          of legal age to form a binding contract or have your parent's permission to do so. You also certify that you are
          legally permitted to use and access the Services and take full responsibility for the selection and use of and
          access to the Services. This agreement is void where prohibited by law, and the right to access the Services is
          revoked in such jurisdictions. f) If you are registering with the Website as a business entity, you represent that
          you have the authority to legally bind that entity. If you are trading as a business, you must comply with and you
          are responsible for all laws applicable to your business. g) If you link to the Website, Company may revoke your
          right to so link at any time, at Company's sole discretion. Company reserves the right to require prior written
          consent before linking to the Website. h) You will indemnify and hold Company, its parents, subsidiaries,
          affiliates, officers, and employees harmless (including, without limitation, from all damages, liabilities,
          settlements, costs and attorneys' fees) from any claim or demand made by any third party due to or arising out of
          your access to the Services, use of the Services, your violation of these Terms and Conditions, or the infringement
          by you or any third party using your account of any intellectual property or other right of any person or entity.
        </p>
        <h4>3. Registration</h4>
        <p>a) You will need to register to become a member of this Website ("Member") if you wish to access the Services via
          this Website, and continued membership is subject to the Company's charges set out in your Pricing plan, located at
          http://www.onehourlearning.com/plans, or the free-trial as deemed by the Company. You are free to change the
          membership package to which you subscribe at any time, subject to these Terms and Conditions. b) To register to
          become a Member you must provide the Company with information about you via the Company's registration form. The
          Company's use of this information is set out in the Company's Privacy Policy which can be found at
          http://onehourlearning.com/privacy. c) You must provide true, accurate, current and complete information about
          yourself when completing the registration form. You must maintain and update this information and keep it true,
          accurate, current and complete. If any information provided by you is not true, accurate, current and complete, the
          Company has the right to cancel your membership and refuse any and all current or future use of this Website. d)
          Registration requires a valid email address (which will serve as your user name) and unique password. Please create
          a secure password: choose a password that uses a combination of letters, upper case and lower case, numbers, and
          symbols. Avoid choosing obvious words or dates such as a nickname or your birth date. Please use maximum caution to
          keep your user name and password confidential and log-off from the Website when your session is complete to prevent
          unauthorized access to your information. If your user name or password is subject to unauthorized access, you should
          immediately inform the Company. e) You must not tell anyone else your user name or password. Any unauthorized use of
          either of them must be reported to <span id="cloak34181"><a
              href="mailto:contact@onehourlearning.com">contact@onehourlearning.com</a></span>
           as soon as you are aware of such use and the Company will, as soon as reasonably possible, cancel your
          user name and/or password and issues new ones to you. f) The Company may, for security or other reasons, require you
          to change your user name and/ or password or other information which facilitates access to this Website or its
          Services. g) The Company reserves the right to cancel your user name and password without notice if the Company
          becomes aware of any breach of these Terms and Conditions by you. h) Once the Company has sent you a confirmation
          e-mail you will become a Member and will be able to access the Services available via the Website.
        </p>
        <h4>4. Charges and Payment</h4>
        <p>a) Charges are linked to the membership package to which you subscribe, as set out in
          http://www.onehourlearning.com/plans, or the free-trial terms as deemed by the Company. b) Payment for all charges
          are payable upon registration or the relevant periodic payment date. You can pay membership fees by cheque, online
          payment methods (NEFT/RTGD/IMPS), any MasterCard, Visa, or American Express credit card; PayPal; or any other
          payment methods the company accepts. c) Your membership package may start with a free trial. The Company reserves
          the right, in its absolute discretion, to determine your eligibility for a free trial, and to withdraw or to modify
          a free trial at any time without prior notice and with no liability.</p>
        <h4>5. Dealings with Third Parties</h4>
        <p>a) The Company is not an agent of any third party or any party named or linked to this Website ("Third Parties")
          and does not have any authority to act for such Third Parties. The Company does not control or endorse and is not
          responsible or liable for any Content, advertising, products or other materials on or available from such Third
          Parties. b) You agree that (to the maximum extent permitted by applicable law) the Company (and our officers,
          directors and employees) shall have no liability to you in relation to any dispute which you may have with a Third
          Party, without limitation one or more of your customers, and/or any other users of this Website.</p>
        <h4>6. Intellectual Property</h4>
        <p>a) You acknowledge and agree that all Content provided on the Website or through the Services, contained in sponsor
          advertisements or, presented to you by the Company, its partners or advertisers, is protected by copyrights,
          trademarks, service marks, patents, or other proprietary rights and laws. You shall abide by all copyright notices,
          trademark rules, information, and restrictions contained in any Content accessed through the Services, and except as
          expressly permitted herein, shall not use, copy, reproduce, modify, translate, publish, broadcast, transmit,
          distribute, perform, upload, display, license, sell or otherwise exploit for any purposes whatsoever any Content or
          third party submissions or other proprietary rights not owned by you: (i) without the express prior written consent
          of the respective owners, and (ii) in any way that violates any third party right. b) We do not claim ownership of
          any Content that you post on the Website or through the Services. Instead, you hereby grant to Company a
          non-exclusive, fully paid and royalty-free, transferable, sub-licensable, perpetual, worldwide license to use the
          Content that you post on the Website or through the Services, subject to the Company's Privacy Policy which can be
          found at http://OneHourLearning.com/privacy. c) The Company hereby grants You (as a User) a limited, non-exclusive,
          non-transferable license to access and use Submitted Content and Company Content, for which You have paid all
          required fees, solely for Your personal, non-commercial, educational purposes through the Site and the Products, in
          accordance with these Terms and any conditions or restrictions associated with particular Courses or Products. All
          other uses are expressly prohibited without our express written consent. You may not reproduce, redistribute,
          transmit, assign, sell, broadcast, rent, share, lend, modify, adapt, edit, create derivative works of, license, or
          otherwise transfer or use any Submitted Content or Company Content unless We give You explicit permission to do so.
          Submitted Content and Company Content is licensed, and not sold, to You. Instructors may not grant You license
          rights to Submitted Content You access or acquire through the Services and any such direct license shall be null and
          void and a violation of these Terms. d) You agree that We may record all or any part of any Courses (including voice
          chat communications) for quality control and delivering, marketing, promoting, demonstrating or operating the Site
          and the Products. You hereby grant the Company permission to use Your name, likeness, image or voice in connection
          with offering, delivering, marketing, promoting, demonstrating, and selling the Site, Products, Courses, Company
          Content and Submitted Content and waive any and all rights of privacy, publicity, or any other rights of a similar
          nature in connection therewith, to the extent permissible under applicable law. e) THE COMPANY RESPECTS ALL
          COPYRIGHT, PRIVACY, DEFAMATION AND OTHER LAWS RELATING TO CONTENT AND INFORMATION AND WILL NOT TOLERATE VIOLATION OF
          SUCH LAWS. IN PARTICULAR, NO REVIEW OR POSTING OR APPEARANCE OF THE SUBMITTED CONTENT ON THE SITE OR THROUGH THE
          PRODUCTS IS INTENDED TO ACT AS AN ENDORSEMENT OR REPRESENTATION THAT ANY SUBMITTED CONTENT IS FREE OF VIOLATION OF
          ANY COPYRIGHT, PRIVACY OR OTHER LAWS OR WILL SUIT A PARTICULAR PURPOSE OR BE ACCURATE OR USEFUL. If You believe that
          Submitted Content of Yours violates any law or regulation or is inaccurate or poses any risk whatsoever to a third
          party it is Your responsibility to take such steps You deem necessary to correct the situation. If You believe that
          Submitted Content of a third party or any Company Content violates any laws or regulations, including, without
          limitation, any copyright laws, You should report it to the Company in accordance with the procedures that We
          maintain at <span id="cloak14679"><a
              href="mailto:contact@onehourlearning.com">contact@onehourlearning.com</a></span>
          f) All rights not expressly granted in these Terms are retained by the Content owners and these Terms do
          not grant any implied licenses.
        </p>
        <h4>7. Termination</h4>
        <p>a) Without prejudice to any remedy that the Company may have against you, the Company may terminate or suspend with
          immediate effect and without notice your access to and use of this Website and your membership if: i. It does not
          receive timely payment under your subscription; ii. It reasonably believes that you have breached any of these Terms
          and Conditions; iii. It is unable to verify the accuracy or validity of any information provided by you; iv. Or it
          suspects fraudulent, abusive or illegal activity by you. b) Should you object to any of these Terms and Conditions,
          or any subsequent changes to them, or become dissatisfied with this Website in any way, your only recourse is to
          immediately discontinue to access or use this Website. c) You may cancel your membership at any time. Cancellation
          will take effect from the next payment period and you will not be entitled to a refund for any membership fee
          already paid.</p>
        <h4>8. Warranty Disclaimer and Limitation of Liability</h4>
        <p>a) Company has no special relationship with or fiduciary duty to you. You acknowledge that Company has no control
          over, and no duty to take any action regarding: which users gain access to the Services; what Content you access via
          the Services; what effects the Content may have on you; how you may interpret or use the Content; or what actions
          you may take as a result of having been exposed to the Content. You release Company from all liability for you
          having acquired or not acquired Content through the Services. The Services may contain, or direct you to websites
          containing, information that some people may find offensive or inappropriate. THE SERVICES, CONTENT, WEBSITE AND ANY
          SOFTWARE ARE PROVIDED ON AN "AS IS" BASIS, WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING,
          WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR
          THAT USE OF THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN
          IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU. b) IN NO EVENT SHALL COMPANY OR ITS
          SUPPLIERS, OR THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE WITH RESPECT TO THE WEBSITE OR
          THE SERVICES OR THE SUBJECT MATTER OF THIS AGREEMENT UNDER ANY CONTRACT, NEGLIGENCE, TORT, STRICT LIABILITY OR OTHER
          LEGAL OR EQUITABLE THEORY (I) FOR ANY AMOUNT IN THE AGGREGATE IN EXCESS OF THE GREATER OF THE FEES PAID BY YOU FOR
          THE SERVICES PURCHASED THROUGH THE WEBSITE DURING THE SIX-MONTH PERIOD PRECEDING THE APPLICABLE CLAIM; (II) FOR ANY
          SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES OF ANY KIND WHATSOEVER; (III) FOR DATA LOSS OR
          COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES; OR (IV) FOR ANY MATTER BEYOND COMPANY'S REASONABLE CONTROL.
          SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE
          LIMITATIONS AND EXCLUSIONS MAY NOT APPLY TO YOU. c) Amendment to these Terms and Conditions d) The Company reserves
          the right to amend and alter these Terms and Conditions and any Content or information contained on the Website at
          any time. In the event these Terms and Conditions are altered the Company will post the amended Terms and Conditions
          on this Website. Changes will apply from the date of posting. e) If after the Terms and Conditions and/or the
          Privacy Policy http://OneHourLearning.com/privacy have been amended or altered you do not agree to their terms, you
          must stop using this Website.</p>
        <h4>9. General</h4>
        <p>a) Whilst the Company will try to provide you with uninterrupted access to this Website and its Services, The
          Company may need to withdraw, modify, discontinue or temporarily or permanently suspend one or more aspects of this
          Website where the Company has a legal, technical or other good reason to do so (including technical difficulties
          experienced by the Company or any Internet infrastructure). However, The Company will try, wherever possible, to
          give reasonable notice of the Company's intention to do so. b) The Company reserves the right to withhold, remove
          and or discard any Content available as part of your account, including any Content contributed by you, with or
          without notice if deemed by the Company to be contrary to these Terms and Conditions. The Company has no obligation
          to store, maintain or provide you a copy of any Content that you provide when using the Services. c) If the Company
          fails to exercise or enforce a right under the Terms and Conditions that failure shall not constitute a waiver of
          such right or provision. d) If any provision of these Terms and Conditions is found by a court of competent
          jurisdiction to be invalid, that provision shall be deemed severed and the validity and enforceability of the
          remaining provisions shall not be affected. The parties nevertheless agree that the court should endeavor to give
          effect to the parties' intentions as reflected in the provision. e) These Terms and Conditions set out the Company's
          entire agreement and understanding with respect to the subject matter of these Terms and Conditions and supersedes
          all representations, communications and prior agreements (written or oral). f) Each party acknowledges that on
          entering into these Terms and Conditions, it does not rely, and has not relied, upon any representation (whether
          negligent or innocent), statement or warranty made or agreed to by any person (whether a party to these Terms and
          Conditions or not) except those expressly set out in these Terms and Conditions. g) Unless expressly provided in the
          Terms and Conditions no term of them is enforceable by any person who is not a party to it. h) You must report any
          violations of these Terms and Conditions to <span id="cloak22198"><a
              href="mailto:contact@onehourlearning.com">contact@onehourlearning.com</a></span>
        </p>
      </div>
      
</div>

<app-footer></app-footer>