<app-header></app-header>
<div class="payment-success">
  <img src="../../../../../../../assets/img/thank-you.png" alt="thank-you">
  <div class="text-center">
    <h2>Thank You</h2>
    <p class="m-1 text-color">
      Thank you for sharing your contact details.<br>
      We will reach out to you shortly to discuss your <br>
      requirements and how we can be of help
    </p>
    <button mat-flat-button class="btn-login" [routerLink]="['']">Home</button>
  </div>
</div>

<app-footer></app-footer>