import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenService } from '../../core/services/token.service';
@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(private http: HttpClient,
    private tokenService: TokenService) { }

  getMailTemplateSettings() {
    return this.http.get('getRemainderEmailTemplate/?userId=' + this.tokenService.getUserId() + '&groupId=' + this.tokenService.getGroupId());
  }

  updateRemainderMailSettings(data: any, frequencyId: number) {
    return this.http.post('updateRemainderEmail/'+ frequencyId +'?userId='+this.tokenService.getUserId() + '&groupId=' + this.tokenService.getGroupId() ,data);
  }

  updateRemainderMailSettingsStatus(data: any, id: number) {
    return this.http.post('updateStatusRemainderEmail/' + id + '?userId='+this.tokenService.getUserId() + '&groupId=' + this.tokenService.getGroupId() ,data);
  }

  sendMailImmediately(data: any) {
    return this.http.post('sendMailImmediately?userId='+this.tokenService.getUserId() + '&groupId=' + this.tokenService.getGroupId() ,data);
  }
}
