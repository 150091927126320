import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'hammerjs';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

if ('serviceWorker'  in  navigator  && environment.production) {
  console.log("Service Worker in main.ts");
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
