import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from '../../../../../core/services/token.service';
declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  loggedIn: boolean;
  constructor(private router: Router,
    public tokenService: TokenService) { }

  ngOnInit(): void {
    const u_data = JSON.parse(localStorage.getItem('userData'));
    if (u_data) {
      this.loggedIn = true;
    }

    $(window).scroll(function() {
      const scrollDistance = $(window).scrollTop();

      // Assign active class to nav links while scolling
      $('.page-section').each(function(i) {
          if ($(this).position().top <= scrollDistance) {
              $('.navbar-nav a.active').removeClass('active');
              $('.navbar-nav a').eq(i).addClass('active');
          }
      });
  }).scroll();


    $(function () {
    $(document).scroll(function () {
      const $nav = $('.fixed-top');
      $nav.toggleClass('scrolled shadow', $(this).scrollTop() > $nav.height());
      $('.navbar-nav').toggleClass('mt-5', $(this).scrollTop() < $nav.height());
    });

    $('#navbarNavDropdown').click(() => {
     $('.collapse').toggle(300);
    });
  });

  }


  /**
  *  on click of dashboard redirect,
  * @method goTo
  */
   goTo() {
    if (this.tokenService.isRole() === 4) {
      this.router.navigate(['/group-admin/content']);
    } else if (this.tokenService.isRole() === 5) {
      this.router.navigate(['/user/content']);
    } else if (this.tokenService.isRole() === 1) {
    this.router.navigate(['/root-admin/admins']);
  } else {
      this.router.navigate(['/admin/courses']);
    }
  }

  /**
  *  logout function,
  * @method logout
  */
logout() {
    localStorage.removeItem('userData');
    localStorage.removeItem('appearanceSettings');
    this.loggedIn = false;
    this.router.navigate(['/public/login']);
  }

  /**
  *  navigate to cart page,
  * @method goToCart
  */
  goToCart() {
    this.router.navigate(['/cart']);
  }

}
