<app-header></app-header>



<div class="payment-success">
  <img src="../../../../../../../assets/img/payment-success.png" alt="payment-success">
  <div class="text-center">
    <h2>Your payment is Successful</h2>
    <span class="text-color">Thank you for your payment. An automated payment receipt</span>
    <p class="m-1 text-color">Will be sent to your registered email</p>
    <button mat-flat-button class="btn-login">Go to Login</button>
  </div>
</div>

<app-footer></app-footer>