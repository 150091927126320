
import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup , Validators, FormControl} from '@angular/forms';
import { CustomValidators  } from 'ngx-custom-validators';
import { PublicService } from '../../services/public.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ValidationMessages } from '../../../../shared/messages';

/**
 * This component is forget password component in which there is form in which user can add its
 * email address and receive reset password mail link on its email address
 */

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  public form: FormGroup;
  validationMessage: any;
  constructor(private fb: FormBuilder,
              private publicService: PublicService,
              public snackBar: MatSnackBar) {}
  loader = false;
  step2 = false;
  step1 = true;
  token: any;

  ngOnInit() {
    this.validationMessage = ValidationMessages;
    this.form = this.fb.group({
      email: [null, Validators.compose([Validators.required, CustomValidators.email])
      ]
    });
  }


 /**
   * This function open toaster msg,
   * @param message snackbar message.
   * @param action message type.
   */
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
      panelClass: ['blue-snackbar']
    });
  }

 /**
 *  forget password form submit function
 * @method onSubmit
 */
  onSubmit() {
    this.loader = true;
    const data = {
      email: this.form.value.email,
      url: 'https://' + window.location.hostname + '/public/reset-password'
    };

    this.publicService.forgetPassword(data).subscribe((res: any) => {
        this.loader = false;
        this.step1 = false;
        this.step2 = true;
        this.openSnackBar(res.Comments, 'Success');
    }, (error: any) => {
      console.log(error);
      this.loader = false;
      this.openSnackBar( error.error.Comments, 'Error');
    });
  }
}
