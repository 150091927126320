import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TokenService } from "../../../../core/services/token.service";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";

@Component({
  selector: "app-supports",
  templateUrl: "./supports.component.html",
  styleUrls: ["./supports.component.scss"],
})
export class SupportsComponent implements OnInit {
  userEmail: string;
  src: any = "assets/images/logo-light-icon.png";
  constructor(private router: Router, private tokenService: TokenService) {
    this.userEmail = this.tokenService.getLoggedInUserEmail();
  }
  public config: PerfectScrollbarConfigInterface = {};
  // src: any;

  // This is for Notifications
  // tslint:disable-next-line: member-ordering
  notifications: Object[] = [
    {
      round: "round-danger",
      icon: "ti-link",
      title: "Launch Admin",
      subject: "Just see the my new admin!",
      time: "9:30 AM",
    },
    {
      round: "round-success",
      icon: "ti-calendar",
      title: "Event today",
      subject: "Just a reminder that you have event",
      time: "9:10 AM",
    },
    {
      round: "round-info",
      icon: "ti-settings",
      title: "Settings",
      subject: "You can customize this template as you want",
      time: "9:08 AM",
    },
    {
      round: "round-primary",
      icon: "ti-user",
      title: "Pavan kumar",
      subject: "Just see the my admin!",
      time: "9:00 AM",
    },
  ];

  // This is for My-messages
  mymessages: Object[] = [
    {
      useravatar: "assets/images/users/1.jpg",
      status: "online",
      from: "Pavan kumar",
      subject: "Just see the my admin!",
      time: "9:30 AM",
    },
    {
      useravatar: "assets/images/users/2.jpg",
      status: "busy",
      from: "Sonu Nigam",
      subject: "I have sung a song! See you at",
      time: "9:10 AM",
    },
    {
      useravatar: "assets/images/users/2.jpg",
      status: "away",
      from: "Arijit Sinh",
      subject: "I am a singer!",
      time: "9:08 AM",
    },
    {
      useravatar: "assets/images/users/4.jpg",
      status: "offline",
      from: "Pavan kumar",
      subject: "Just see the my admin!",
      time: "9:00 AM",
    },
  ];
  ngOnInit(): void {}

  logout() {
    localStorage.removeItem("userData");
    localStorage.removeItem("appearanceSettings");
    this.router.navigate(["/public/login"]);
  }
}
