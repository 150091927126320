import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../../src/environments/environment';
import { AuthService } from '../services/auth.service';
import { TokenService } from '../services/token.service';
@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService,
    public tokenService: TokenService,
    private router: Router) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    let modifiedUrl = request.clone({
      url: request.url
    });
    if (!(request.url).includes('/login') || !(request.url).includes('/register')) {
      const token = this.authService.getUserToken();
      if (token) {
        if (this.tokenService.isRole() !== 1) {
          request = request.clone({
            headers: request.headers.set('Authorization', token),
            params: request.params.set(
              'companyId',
              this.tokenService.getCompanyId()
            ),
          });


        } else {
          request = request.clone({
            headers: request.headers.set('Authorization', token)
          });
        }
      }

    }

    if (!request.headers.has('Content-Type')) {
      request = request.clone({
        // headers: request.headers.set('Content-Type', 'application/json')
      });
    }


    if (!request.url.includes('group-user.csv') === true) {
      modifiedUrl = request.clone({
        url: environment.api_base_url + request.url
      });
    }

    return next.handle(modifiedUrl).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (event.body.ResponseCode === 1 || event.body.success === true) {
            console.log('event--->>>', event);
            return event;
          }
        }

      }),

      catchError((error: HttpErrorResponse) => {
        let data = {};
        data = {
          reason: error && error.error ? error.error : '',
          status: error.status
        };
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            this.router.navigateByUrl('/login');
          }
        }
        return throwError(error);
      })
    );
  }
}
