import { Injectable } from '@angular/core';
import { TokenService } from '../../core/services/token.service';


export interface BadgeItem {
  type: string;
  value: string;
}
export interface Saperator {
  name: string;
  type?: string;
}
export interface SubChildren {
  state: string;
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  child?: SubChildren[];
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  saperator?: Saperator[];
  children?: ChildrenItems[];
}

const MENUITEMSFORADMIN = [
   {
      state: 'admin/courses',
      name: 'Courses',
      type: 'link',
      icon: 'content_copy',

    },
    {
      state: 'admin/folders',
      name: 'Folders',
      type: 'link',
      icon: 'content_copy',

    },
    {
      state: 'admin/reports',
      name: 'Reports',
      type: 'link',
      icon: 'content_copy',

    },
    {
      state: 'admin/people',
      name: 'People',
      type: 'link',
      icon: 'content_copy',

    },
    {
      state: 'admin/settings',
      name: 'Settings',
      type: 'link',
      icon: 'content_copy',

    },
    {
      state: 'material',
      name: 'Material Ui',
      type: 'sub',
      icon: 'bubble_chart',
      badge: [{ type: 'red', value: '17' }],
      children: [
        { state: 'badge', name: 'Badge', type: 'link' },
        { state: 'button', name: 'Buttons', type: 'link' },
        { state: 'cards', name: 'Cards', type: 'link' },
        { state: 'grid', name: 'Grid List', type: 'link' },
        { state: 'lists', name: 'Lists', type: 'link' },
        { state: 'menu', name: 'Menu', type: 'link' },
        { state: 'tabs', name: 'Tabs', type: 'link' },
        { state: 'stepper', name: 'Stepper', type: 'link' },
        { state: 'ripples', name: 'Ripples', type: 'link' },
        { state: 'expansion', name: 'Expansion Panel', type: 'link' },
        { state: 'chips', name: 'Chips', type: 'link' },
        { state: 'toolbar', name: 'Toolbar', type: 'link' },
        { state: 'progress-snipper', name: 'Progress snipper', type: 'link' },
        { state: 'progress', name: 'Progress Bar', type: 'link' },
        { state: 'dialog', name: 'Dialog'},
      ]
    }
];

const MENUITEMSFORGROUPADMIN = [
  {
    state: 'group-admin/content',
    name: 'Content',
    type: 'link',
    icon: 'content_copy',

  },
  {
    state: 'group-admin/internal/courses',
    name: 'Internal Courses',
    type: 'link',
    icon: 'content_copy',

  },
  {
    state: 'group-admin/reports',
    name: 'Reports',
    type: 'link',
    icon: 'content_copy',

  },
  {
    state: 'group-admin/people',
    name: 'People',
    type: 'link',
    icon: 'content_copy',

  },
  {
    state: 'group-admin/settings',
    name: 'Settings',
    type: 'link',
    icon: 'content_copy',

  },
  {
    state: 'group-admin/leader-board',
    name: 'Leader-board',
    type: 'link',
    icon: 'content_copy',

  }, 

];

const MENUITEMSFORNORMALUSER = [
  {
    state: 'user/content',
    name: 'Content',
    type: 'link',
    icon: 'content_copy',

  },

  {
    state: 'user/settings',
    name: 'Settings',
    type: 'link',
    icon: 'content_copy',

  },
  {
    state: 'user/leader-board',
    name: 'Leader-board',
    type: 'link',
    icon: 'content_copy',

  }, 

];
const MENUITEMSFORROOTUSER = [

];

@Injectable()
export class MenuItems {
  constructor(public tokenService: TokenService) {

  }
  getMenuitem(): Menu[] {
    if (this.tokenService.isRole() === 5) {
      return MENUITEMSFORNORMALUSER;
    }
    if (this.tokenService.isRole() === 4) {
      return MENUITEMSFORGROUPADMIN;
    }
    if (this.tokenService.isRole() === 1) {
      return MENUITEMSFORROOTUSER;
    }
    if (this.tokenService.isRole() === 3 || 2) {
      return MENUITEMSFORADMIN;
    }

  }
}
