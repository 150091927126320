
<div class="login-register" style="background-image:url(assets/images/background/login-register.jpg);">
    <div class="login-register-box">
      <mat-card>
        <mat-card-content >
          <form [formGroup]="form" (ngSubmit)="submitForm()">
            <div class="text-center">
              <img alt="homepage" src="assets/img/logo.png" width="150" class="m-b-20">
              <h4 class="m-t-0">Reset Password (Step 1)</h4>
            </div>
            <p class="text-center font-14">Enter your new password and then confirms your new password.</p>
            <div class="overlay" *ngIf="loader">
        <mat-spinner></mat-spinner>
    </div>
            <div fxLayout="column" fxLayoutAlign="space-around">
              <div class="pb-1">
                <mat-form-field style="width: 100%">
                  <input matInput placeholder="Password" type="password" [formControl]="form.controls['password']">
                </mat-form-field>
                <small *ngIf="form.controls['password'].hasError('required') && form.controls['password'].touched" class="text-danger support-text">{{validationMessage.mustPresentPassword}}</small>
                <small *ngIf="form.controls['password'].errors?.email && form.controls['password'].touched" class="text-danger support-text">{{validationMessage.mustPresentPassword}}</small>
                
                <mat-form-field style="width: 100%">
                    <input matInput placeholder="Confirm Password" type="password" [formControl]="form.controls['confirmPassword']">
                  </mat-form-field>
                  <small *ngIf="form.controls['confirmPassword'].hasError('required') && form.controls['confirmPassword'].touched" class="text-danger support-text">{{validationMessage.mustPresentConfirmPassword}}</small>
                  <small *ngIf="form.controls['confirmPassword'].errors?.email && form.controls['confirmPassword'].touched" class="text-danger support-text">{{validationMessage.mustPresentConfirmPassword}}</small>
              </div>
              <button mat-raised-button color="primary" type="submit" class="btn-block btn-lg m-t-20" [disabled]="!form.valid">Submit</button>
            </div>
  
          </form>
        </mat-card-content>
      </mat-card>
    </div>
  </div>


