<!-- <div class="row justify-content-center m-0 footer-content my-5">
  <a href="terms-condition">Terms & conditions
  </a>
  <a href="privacy-policy" class="mx-5">Privacy policy
  </a>
  <a href="credit">Credits
  </a>
</div> -->


<div class="footer">
  <ul>
    <li><a href="terms-condition">Terms & conditions
    </a></li>
    <li><a href="privacy-policy" class="mx-5">Privacy policy
    </a></li>
    <li><a href="credit">Credits
    </a></li>
  </ul>
  </div>
    
<!-- <div class="contact-us">
    <h3>Contact us:</h3>
    <div class="contact-wrapper">
      <a href="">learn@onehourlearning.com</a>
      <p>+91-9212100436<br>
        +91-8451046147  
      </p>
    </div>
  </div> -->