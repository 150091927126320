import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RootAdminComponent } from './root-admin.component';
import { DemoMaterialModule } from '../../demo-material-module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RootAdminRoutingModule } from './root-admin-routing.module';
import { AdminsComponent } from './components/admins/admins.component';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    DemoMaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    RootAdminRoutingModule,
  ]
})
export class RootAdminModule { }
