import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RootAdminComponent } from './root-admin.component';
import { AdminsComponent } from './components/admins/admins.component';


const routes: Routes = [{
  path: '',
  component: RootAdminComponent,
  children: [
    {
      path: 'admins',
      component: AdminsComponent,
    },
  ]
},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RootAdminRoutingModule { }
