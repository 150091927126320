import { NgModule, ModuleWithProviders } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MenuItems } from './menu-items/menu-items';
import {
  AccordionAnchorDirective,
  AccordionLinkDirective,
  AccordionDirective
} from './accordion';
import { DemoMaterialModule } from '../demo-material-module';
import { Routes, RouterModule } from "@angular/router";
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { UploadCourseComponent } from './components/upload-course/upload-course.component';
import { UtilServiceService } from './services/util-service.service';
import { GroupAppearanceComponent } from './components/group-appearance/group-appearance.component';
import {CourseSequencingComponent } from './components/group-mapping/course-sequencing/course-sequencing.component';
import { SequencingByDateComponent } from './components/group-mapping/sequencing-by-date/sequencing-by-date.component';
import { SequencingByCompletionComponent } from './components/group-mapping/sequencing-by-completion/sequencing-by-completion.component';
import { EmailComponent } from './components/email-setting/email/email.component';
import { EmailTemplateComponent } from './components/email-setting/email-template/email-template.component';
import { SettingsComponent } from './components/email-setting/settings/settings.component';
import { CompletionMailComponent } from './components/email-setting/email-template/completion-mail/completion-mail.component';
import { EmailTemplatesSidebarComponent } from './components/email-setting/email-template/email-templates-sidebar/email-templates-sidebar.component';
import { RegistrationMailComponent } from './components/email-setting/email-template/registration-mail/registration-mail.component';
import { ReminderMailComponent } from './components/email-setting/email-template/reminder-mail/reminder-mail.component';
import { LoginMailComponent } from './components/email-setting/email-template/login-mail/login-mail.component';
import { NgxSummernoteModule } from 'ngx-summernote';
import { SharedService } from './services/shared.service';

@NgModule({

  imports: [
    CommonModule,
    DemoMaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSummernoteModule,
    RouterModule,
  ],

  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    UploadCourseComponent,
    GroupAppearanceComponent,
    CourseSequencingComponent,
    SequencingByDateComponent,
    SequencingByCompletionComponent,
    EmailComponent,
    EmailTemplateComponent,
    SettingsComponent,
    CompletionMailComponent,
    EmailTemplatesSidebarComponent,
    RegistrationMailComponent,
    ReminderMailComponent,
    LoginMailComponent
  
  ],
  exports: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    UploadCourseComponent,
    GroupAppearanceComponent,
    CourseSequencingComponent,
    RouterModule,
    
  ],
  providers: [MenuItems, UtilServiceService, SharedService]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
                    ]
    };
  }
 }
