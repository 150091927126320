import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenService } from './token.service';

/**
 * This service allows the only logged in user to go through the app
 * based on the role type
 */

@Injectable({
  providedIn: 'root'
})
export class AfterLoginService {
  public userRole: any;

  constructor(private Token: TokenService,
              private router: Router) {
  }

  /**
   *  this only allows the users to go through the routes after login only
   * @method canActivate
   * @param ActivatedRouteSnapshot -  a route associated with a component loaded in an outlet at a particular moment in time.
   * @param RouterStateSnapshot - Represents the state of the router at a moment in time.
   * @param Observable - interface to handle a variety of common asynchronous operations.
   * @param Promise -  It serves the same function as callbacks but has a nicer syntax and makes it easier to handle errors.
   */
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {

    if (localStorage.getItem('clientData')) {
      const data = localStorage.getItem('clientData');
      const parsedata = JSON.parse(data);
      this.userRole = parsedata.roleId;
      return true;
    } else {
      this.userRole = this.Token.isRole();
    }

    if (typeof next.data.role === 'number') {
      if (this.userRole === next.data.role && this.Token.loggedIn() === true) {
        return true;
      }
    } else {
      if (next.data.role.includes(this.userRole) && this.Token.loggedIn() === true) {
        return true;
      }
    }
    this.router.navigateByUrl('/');
    return false;
  }
}
