export const button = {
    addCourse: {
        color: 'primary',
        title: 'Add New Course',
        type: 'mat-raised-button'
    },
    addFolder: {
        color: 'primary',
        title: 'Create New Folder',
        type: 'mat-raised-button'
    },
    cancel: {
        color: 'warn',
        title: 'Cancel',
        type: 'mat-raised-button'
    },
    submit: {
        color: 'primary',
        title: 'Submit',
        type: 'mat-raised-button'
    },
    confirm: {
        color: 'primary',
        title: 'Confirm',
        type: 'mat-raised-button'
    },
    upload: {
        color: 'primary',
        title: 'Upload File',
        type: 'mat-raised-button'
    },
    yes: {
        color: 'primary',
        title: 'Yes',
        type: 'mat-raised-button'
    },
    no: {
        color: 'warn',
        title: 'No',
        type: 'mat-raised-button'
    },
    launchCourse: {
        color: 'primary',
        title: 'Launch Course',
        type: 'mat-raised-button'
    },
    update: {
        color: 'primary',
        title: 'Update',
        type: 'mat-raised-button'
    },
    viewAssignedUsers: {
        color: 'primary',
        title: 'View Assigned Users',
        type: 'mat-raised-button'
    },

    changePassword: {
        color: 'primary',
        title: 'Change Password',
        type: 'mat-raised-button'
    },
    updatePassword: {
        color: 'primary',
        title: 'Update Password',
        type: 'mat-raised-button'
    },
    updateMasterPassword: {
        color: 'primary',
        title: 'Update Master Password',
        type: 'mat-raised-button'
    },
    createSuperAdmin: {
        color: 'primary',
        title: 'Create Super Admin',
        type: 'mat-raised-button'
    },
    manageSuperAdmin: {
        color: 'primary',
        title: 'Manage Super Admin',
        type: 'mat-raised-button'
    },
    newUser: {
        color: 'primary',
        title: 'Create New User',
        type: 'mat-raised-button'
    },
    addNewUser: {
        color: 'primary',
        title: 'Add New User',
        type: 'mat-raised-button'
    },
    createNewGroup: {
        color: 'primary',
        title: 'Create New Group',
        type: 'mat-raised-button'
    },
    downloadGroupReport: {
        color: 'primary',
        title: 'Download Overall Group Report',
        type: 'mat-raised-button'
    },
    updateProfile: {
        color: 'primary',
        title: 'Update Profile',
        type: 'mat-raised-button'
    },
    createNewAdmin: {
        color: 'primary',
        title: 'Create New Admin',
        type: 'mat-raised-button'
    },
    assignCourseToUsers: {
        color: 'primary',
        title: 'Assign Course To users',
        type: 'mat-raised-button'
    },
    addBulkUsers: {
        color: 'primary',
        title: 'Add bulk users',
        type: 'mat-raised-button'
    },
    courseAssignment: {
        color: 'primary',
        title: 'Course Assignment',
        type: 'mat-raised-button'
    },
    courseEnrollment: {
        color: 'primary',
        title: 'Course Enrollment',
        type: 'mat-raised-button'
    },
    courseAssignmentCSV: {
        color: 'primary',
        title: 'Course Assignment(CSV)',
        type: 'mat-raised-button'
    },

};
