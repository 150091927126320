// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { button } from './button';
export const environment = {
  production: false,
  //  api_base_url: 'https://staging.api.lms.skunktest.work/v1/', // live version
   api_base_url: 'https://dev.api.lms.skunktest.work/v1/', // dev version
  //  api_base_url_withoutV1: 'https://staging.api.lms.skunktest.work/', // live version
   api_base_url_withoutV1: 'https://dev.api.lms.skunktest.work/', // dev version
  s3_upload_path: 'scorm',

  USER_ROLES : {
    ROOT_ADMIN: 1,
    ADMIN: 3,
    SUPER_ADMIN: 2,
    GROUP_ADMIN: 4,
    USER: 5
  },
  button: button,

  pagination: {
    pageSizeOptions:[10],
    pageLimit:10,
    pageSize:10
  } 
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
