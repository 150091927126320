import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(router: Router) { }

  /**
   *  sets the user details in local storage after user logged in
   * @method loginSuccessResponseHandle
   * @param userData -  user data from api
   */
  async loginSuccessResponseHandle(userData) {
    const userInfo = JSON.stringify(userData);
    await localStorage.setItem('userData', userInfo);
  }

  /**
   *  returns the logged in user details
   * @method getUserDetails
   */
  getUserDetails() {
    const userData = JSON.parse(localStorage.getItem('userData'));
    if (userData) {
      return userData;
    } else {
      return false;
    }
  }

  /**
   *  returns the logged in user token
   * @method getUserToken
   */
  getUserToken() {
    return this.getUserDetails().apiToken;
  }

}
