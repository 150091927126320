<app-header></app-header>
<div>
  <div class="cart">
    <div>
      <h3 class="font-30">Cart</h3>
      <div class="divider"></div>
    </div>
    <div
      fxLayout="row wrap"
      fxLayoutAlign="space-between none"
      fxLayoutGap="17px"
    >
      <div fxFlex.gt-sm="58" fxFlex="100">
        <div *ngIf="selectedCoursePackages.length > 0">
          <div
            class="cart-card"
            fxLayout="row"
            fxLayoutAlign="space-between none"
            *ngFor="let data of selectedCoursePackages"
          >
            <div fxLayout="row" fxLayoutAlign="none center" fxLayoutGap="20px">
              <div class="cart-img">
                <img src="{{ data.banner }}" alt="course images" />
              </div>
              <div class="course-content">
                <span>
                  {{ data.name }}
                </span>
              </div>
            </div>
            <div class="price-row" fxLayoutAlign="none center">
              <div class="text-row" fxLayoutAlign="none center">
                <h2 class="text-primary">{{ data.price }}</h2>
                <span class="price-currency text-primary">INR</span>
              </div>
              <div class="close-icon" (click)="removeFromCart(data)">
                <mat-icon>close</mat-icon>
              </div>
            </div>
          </div>
        </div>
        <div
          class="coupan"
          fxLayout="row wrap"
          fxLayoutAlign="none center"
          fxLayoutGap="17px"
        >
          <div fxFlex="50">
            <form [formGroup]="couponForm">
              <!-- <input type="text" placeholder="Enter your coupon code" class="coupan-form-control w-100"> -->
              <mat-form-field appearance="outline">
                <input matInput formControlName="coupon" />
              </mat-form-field>
            </form>
          </div>
          <div>
            <button mat-flat-button class="btn-apply" (click)="ApplyCoupon()">
              Apply coupon
            </button>
          </div>
          <div class="light-divider"></div>

          <div class="mt-5" fxFlex.gt-sm="72" fxFlex="100">
            <form [formGroup]="form">
              <!-- email id -->
              <div class="input-hint">
                Email address <span class="text-danger">*</span>
              </div>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="email"
                  (change)="checkEmail()"
                />
                <small
                  *ngIf="
                    form.controls['email'].hasError('required') &&
                    form.controls['email'].touched
                  "
                  class="text-danger support-text"
                  >{{ validationMessage.emailRequired }}</small
                >
                <small
                  *ngIf="
                    form.controls['email'].hasError('pattern') &&
                    form.controls['email'].touched
                  "
                  class="text-danger support-text"
                  >{{ validationMessage.emailRequired }}
                </small>
              </mat-form-field>

              <!-- first name -->
              <div class="input-hint">
                First name <span class="text-danger">*</span>
              </div>
              <mat-form-field appearance="outline">
                <input matInput formControlName="firstName" />
                <small
                  *ngIf="
                    form.controls['firstName'].hasError('required') &&
                    form.controls['firstName'].touched
                  "
                  class="text-danger support-text"
                  >{{ validationMessage.firstNameRequired }}</small
                >
              </mat-form-field>

              <!-- last name -->
              <div class="input-hint">
                Last name <span class="text-danger">*</span>
              </div>
              <mat-form-field appearance="outline">
                <input matInput formControlName="lastName" />
                <small
                  *ngIf="
                    form.controls['lastName'].hasError('required') &&
                    form.controls['lastName'].touched
                  "
                  class="text-danger support-text"
                  >{{ validationMessage.lastNameRequired }}</small
                >
              </mat-form-field>

              <!-- Password -->
              <div *ngIf="showPassword === true" class="input-hint">
                Password <span class="text-danger">*</span>
              </div>
              <mat-form-field
                appearance="outline"
                *ngIf="showPassword === true"
              >
                <input type="password" matInput formControlName="password" />
                <small
                  *ngIf="
                    form.controls['password'].hasError('required') &&
                    form.controls['password'].touched
                  "
                  class="text-danger support-text"
                  >{{ validationMessage.passwordRequired }}</small
                >
              </mat-form-field>

              <!--Confirm password -->
              <div *ngIf="showPassword === true" class="input-hint">
                Confirm password <span class="text-danger">*</span>
              </div>
              <mat-form-field
                appearance="outline"
                *ngIf="showPassword === true"
              >
                <input
                  type="password"
                  matInput
                  formControlName="confirmPassword"
                />
                <small
                  *ngIf="
                    form.controls['confirmPassword'].hasError('required') &&
                    form.controls['confirmPassword'].touched
                  "
                  class="text-danger support-text"
                  >{{ validationMessage.mustPresentConfirmPassword }}</small
                >
                <small
                  *ngIf="
                    form.controls['confirmPassword'].touched &&
                    form.controls['confirmPassword'].value !==
                      form.controls['password'].value
                  "
                  class="text-danger support-text"
                  >{{ validationMessage.passwordNotMatching }}</small
                >
              </mat-form-field>
              <!-- <div class="text-center">
                <button class="btn-submit" type="submit" mat-flat-button>Submit</button>
              </div> -->
            </form>
          </div>
        </div>
      </div>
      <div fxFlex.gt-sm="38" fxFlex="100">
        <div class="cart-total bg-white">
          <div class="cart-heading">
            <h3 class="p-t-20">Cart Total</h3>
          </div>
          <div class="divider"></div>
          <div class="cart-calculation">
            <div class="price-total">
              <span>Subtotal</span>
              <span>{{ subTotal | number: "1.0":"en-US" }}</span>
            </div>
            <div class="price-total">
              <span>Coupon</span>
              <span>{{ couponAmount }}</span>
            </div>
            <div class="divider"></div>
            <div class="price-total">
              <span>Total</span>
              <span>{{ totalPayable | number: "1.0":"en-US" }}</span>
            </div>
            <div class="divider"></div>
            <div [ngStyle]="{ 'margin-top.px': 40 }">
              <button
                mat-flat-button
                class="checkout-btn"
                (click)="proceedToPay()"
              >
                Proceed to checkout
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
