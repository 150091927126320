import {Component, OnInit} from '@angular/core';
import { RootAdminService } from '../../../../services/root-admin.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../../../../../../environments/environment';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TokenService } from '../../../../../../core/services/token.service';
import { ValidationMessages } from '../../../../../../shared/messages';


/**
 * This component containing the modal
 * for create the master admin
 */
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-createEdit.dialog',
  templateUrl: '../../dialogs/createEdit/createEdit.dialog.html',
})
export class CreateAdminDialogComponent implements OnInit {
  createMasterAdminForm: FormGroup;
  buttonConfig: any;
  loader = false;
  validationMessage: any;

  constructor(private adminService: RootAdminService,
              public tokenService: TokenService,
              public snackBar: MatSnackBar,
              public dialogRef: MatDialogRef<CreateAdminDialogComponent>,
              ) {
    this.buttonConfig = environment.button;
    this.createMasterAdminForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      domain: new FormControl('', [Validators.required]),
    });
    this.validationMessage = ValidationMessages;

  }

/**
 *  when user clicks on cancel button
 * @method onNoClick
 */
  onNoClick(): void {
    this.dialogRef.close();
  }


    /**
 *  calls when root admin want to create new master admin
 * @method createMasterAdminFormSubmit
 */
  createMasterAdminFormSubmit() {
    this.loader = true;
    const data = {
      email: this.createMasterAdminForm.value.email,
      subdomain: this.createMasterAdminForm.value.domain,
      name: this.createMasterAdminForm.value.name,
    };

    this.adminService.createMasterAdmin(data).subscribe((res: any) => {
        this.loader = false;
        this.openSnackBar(res.Comments, 'Success');
        this.dialogRef.close();
    }, (error: any) => {
      console.log(error);
      this.loader = false;
      this.openSnackBar(error.error.Comments, 'Error');
    });
  }


  ngOnInit() {
  }

   /**
   *  This function open toaster msg,
   * @method openSnackBar
   * @param message snackbar message.
   * @param action message type.
   */
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
      panelClass: ['blue-snackbar']
    });
  }

}
