
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../../../../../app/core/services/auth.service';
import { PublicService } from '../../services/public.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { environment } from '../../../../../environments/environment';
import { ValidationMessages } from '../../../../shared/messages';


/**
 * This component is login component in which there is login form in which user gets logged in by using
 * valid email address and password
 */

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public form: FormGroup;
  validationMessage: any;
  appearanceSettings: any;
  src: any = "assets/img/logo.png"
  constructor(private fb: FormBuilder,
    private router: Router,
    public snackBar: MatSnackBar,
    private authService: AuthService,
    private publicService: PublicService) { 
      this.setAppearance();
    }

  ngOnInit() {
    this.validationMessage = ValidationMessages;
    this.form = this.fb.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: [null, Validators.required]
    });
    this.setAppearance();
  }

  /**
   *  This function open toaster msg,
   * @method openSnackBar
   * @param message snackbar message.
   * @param action message type.
   */
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
      panelClass: ['blue-snackbar']
    });
  }

  /**
   * this function set the appearance for the dashboard according
   * to the domain name
   * @method setAppearance
   */
  setAppearance() {
    this.publicService.getAppearanceSettings('https://'+ window.location.hostname).subscribe((res: any) => {
       localStorage.setItem('appearanceSettings', JSON.stringify(res.Result));
        this.appearanceSettings = res.Result;
        //localStorage.setItem('appearanceSettings', JSON.stringify(this.appearanceSettings));
         this.src = this.appearanceSettings.logo;
        $('button.mat-button-base').css({
          background: this.appearanceSettings.buttonBackgroundColor,
          color : this.appearanceSettings.buttonTextColor
         });
    }, (error: any) => {
      console.log(error);
      this.openSnackBar(error.error.Comments, 'Error');
    });
  }


  /**
   *  login form submit function,
   * @method loginSubmit
   */
  loginSubmit() {

    const data = {
      email: this.form.value.email,
      password: this.form.value.password
    };

    this.publicService.login(data).subscribe((res: any) => {
        this.authService.loginSuccessResponseHandle(res.Result);
        this.openSnackBar(res.Comments, 'Success');
        if (res.Result.roleId === 4) {
         this.router.navigate(['/group-admin/content']);
        } else if (res.Result.roleId === 5) {
          this.router.navigate(['/user/content']);
        } else if (res.Result.roleId === 1) {
          this.router.navigate(['/root-admin/admins']);
        } else {
          this.router.navigate(['/admin/courses']);
        }
    }, (error: any) => {
      console.log(error);
      if (error.message === 'Http failure response for ' + environment.api_base_url + 'login: 0 Unknown Error') {
      } else {
        this.openSnackBar(error.error.Comments, 'Error');
      }
    });
  }
}
