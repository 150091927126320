<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->
<div class="header-img" style="width: fit-content">
  <div>
    <button [matMenuTriggerFor]="profile" mat-icon-button>
      <img
        src="assets/images/avatar.png"
        alt="user"
        class="profile-pic"
        style="margin-left: 30px"
      />
    </button>
    <p class="email-text" style="margin: 0px; white-space: initial; ">
      {{ userEmail }}
    </p>
  </div>

  <mat-menu #profile="matMenu" class="mymegamenu">
    <a mat-menu-item routerLink="/public/support">Support </a>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="logout()">
      <mat-icon>exit_to_app</mat-icon> Sign Out
    </button>
  </mat-menu>
</div>

<div
  style="
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: transparent !important;
  "
  class="header-contents"
>
  <div style="background-color: transparent !important;">
    <button mat-button class="button-header" style="pointer-events: none; cursor: default;background: transparent !important;"
      >{{ userEmail }}
    </button>
  </div>
  <button mat-button  class="button-header" style="width: 0.2vw !important;    padding: 0px !important;
  min-width: 10px; background: rgb(90, 175, 44,0) !important;" type="button">|</button>
  <div><button mat-button class="button-header"  routerLink="/public/support" >Support </button></div>
 <div><button mat-button class="button-header"  style="width: 0.2vw !important;    padding: 0px !important;
  min-width: 10px;" type="button">|</button></div>
  <button mat-button (click)="logout()" class="button-header"  >Logout</button>
</div>
