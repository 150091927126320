<!-- ============================================================== -->
<!-- sidebar -->
<!-- ============================================================== -->

<mat-nav-list >
    <mat-list-item  *ngFor="let menuitem of menuItems.getMenuitem()">
        <a (click)="goTo(menuitem.state)" *ngIf="menuitem.type === 'link'">
            <!-- <mat-icon>{{ menuitem.icon }}</mat-icon> -->
            <span [class] = "getActiveClass(menuitem.name)">{{ menuitem.name }}</span>
            <span fxFlex></span>
            <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
            <!-- <mat-nav-list class="sub-item" *ngIf="menuitem.type === 'sub'">
                <mat-list-item *ngFor="let childitem of menuitem.children" routerLinkActive="selected">
                    <a [routerLink]="['/', menuitem.state, childitem.state ]" class="relative" routerLinkActive="selected">{{
                        childitem.name}}</a>
                </mat-list-item>
            </mat-nav-list> -->
        </a>

     
        <!-- <a class="" appAccordionToggle href="{{menuitem.state}}" *ngIf="menuitem.type === 'extLink'">
            <mat-icon>{{ menuitem.icon }}</mat-icon>
            <span>{{ menuitem.name }}</span>
            <span fxFlex></span>
            <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
        </a>
        <a class="" appAccordionToggle href="{{menuitem.state}}" target="_blank" *ngIf="menuitem.type === 'extTabLink'">
            <mat-icon>{{ menuitem.icon }}</mat-icon>
            <span>{{ menuitem.name}}</span>
            <span fxFlex></span>
            <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
        </a>
        <a class="" appAccordionToggle href="javascript:;" *ngIf="menuitem.type === 'sub'">
            <mat-icon>{{ menuitem.icon }}</mat-icon>
            <span>{{ menuitem.name }}</span>
            <span fxFlex></span>
            <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
            <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
        </a>
        <mat-nav-list class="sub-item" *ngIf="menuitem.type === 'sub'">
            <mat-list-item *ngFor="let childitem of menuitem.children" routerLinkActive="selected">
                <a [routerLink]="['/', menuitem.state, childitem.state ]" class="relative" routerLinkActive="selected">{{
                    childitem.name}}</a>
            </mat-list-item>
        </mat-nav-list>
        <div class="saperator text-muted" *ngIf="menuitem.type === 'saperator'">
            <span>{{ menuitem.name }}</span>
        </div> -->
    </mat-list-item>
    <!-- *ngIf="userRole=='2'" -->
    <mat-list-item  *ngIf="userRole=='2'" >
        <a >
            <button mat-button [matMenuTriggerFor]="menu">Service</button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item [routerLink]="['/admin/leads']">Lead</button>
              <button mat-menu-item [routerLink]="['/admin/subscription']">Subscription</button>
              <button mat-menu-item [routerLink]="['/admin/service/coupon']">Coupon</button>
            </mat-menu>  
        </a>
    </mat-list-item >

  
</mat-nav-list>