
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PublicService } from '../../services/public.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ValidationMessages } from '../../../../shared/messages';

/**
 * This component is reset password component in which there is form in which user can change its
 * password and confirm it
 */

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public form: FormGroup;
  loader = false;
  token: any;
  validationMessage: any;
  constructor(private fb: FormBuilder,
    private router: Router,
    private publicService: PublicService,
    public snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.validationMessage = ValidationMessages;
    this.form = this.fb.group({
      password: [null, Validators.required],
      confirmPassword: [null, Validators.required],
    });
    this.activatedRoute.queryParams.subscribe(params => {
      this.token = params.token;
    });
  }

  /**
    * This function open toaster msg
    * @param message snackbar message.
    * @param action message type.
    */
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
      panelClass: ['blue-snackbar']
    });
  }

  /**
   * reset password form submit function
   * @method submitForm
   */
  submitForm() {

    // tslint:disable-next-line: triple-equals
    if (this.form.value.password != this.form.value.confirmPassword) {
      this.openSnackBar(ValidationMessages.passwordNotMatching, 'Error');
      return;
    }

    const data = {
      password: this.form.value.password,
      token: this.token
    };
    this.loader = true;
    this.publicService.resetPassword(data).subscribe((res: any) => {
        this.loader = false;
        this.openSnackBar(res.Comments, 'success');
        this.router.navigate(['/public/login']);

    }, (error: any) => {
      console.log(error);
      this.loader = false;
      this.openSnackBar(error.error.Comments, 'Error');
    });
  }

}
