import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicComponent } from './public.component';
import { LoginComponent } from './components/login/login.component';
import { DemoMaterialModule } from '../../demo-material-module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PublicRoutingModule } from './public-routing.module';
import { ForgotPasswordComponent } from './components/forget-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { HomeComponent } from './components/home/home.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { CreditComponent } from './components/credit/credit.component';
import { CourseLibraryComponent } from './components/course-library/course-library.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { TermAndConditionsComponent } from './components/term-and-conditions/term-and-conditions.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { CoursePackagesComponent } from './components/subscription/individual/course-packages/course-packages.component';
import { CartComponent } from './components/subscription/individual/cart/cart.component';
import { TeamDetailsComponent } from './components/subscription/team/team-details/team-details.component';
import { TeamCoursePackagesComponent } from './components/subscription/team/team-course-packages/team-course-packages.component';
import { PaymentSuccessfulComponent } from './components/subscription/team/payment-successful/payment-successful.component';
import { ThankYouComponent } from './components/subscription/team/thank-you/thank-you.component';
import { MatIconModule } from '@angular/material/icon';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ThankYouIndividualComponent } from './components/subscription/individual/thank-you-individual/thank-you-individual.component';
import { SupportsComponent } from './components/supports/supports.component';
import { MobileContactUsComponent } from './components/mobile-contact-us/mobile-contact-us.component';


@NgModule({
  declarations: [PublicComponent, LoginComponent, ForgotPasswordComponent,
    ResetPasswordComponent, HomeComponent, PrivacyPolicyComponent, CreditComponent,
     CourseLibraryComponent, ContactUsComponent, TermAndConditionsComponent, HeaderComponent,
      FooterComponent, CoursePackagesComponent, CartComponent, TeamDetailsComponent, TeamCoursePackagesComponent, PaymentSuccessfulComponent, ThankYouComponent, ThankYouIndividualComponent, SupportsComponent, MobileContactUsComponent],
  imports: [
  CommonModule,
    DemoMaterialModule,
    FlexLayoutModule,
    FormsModule,
    MatIconModule,
    ReactiveFormsModule,
    PublicRoutingModule,
    NgMultiSelectDropDownModule.forRoot()
  ]
})
export class PublicModule { }
