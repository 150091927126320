import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  installMessage:string = 'Install this webApp on your iPhone. Tap on share icon and then add to HomeScreen';

  constructor(public snackBar: MatSnackBar
    ) {
  }

  ngOnInit(): void {    
    if(window.location.href.indexOf("mobile-contact-us") == -1){
      this.checkIfIOSDevice();
    }
  }

  /**
   *  It checks if should display install PWA popup notification on IOS devices
   * @method checkIfIOSDevice
   */
  checkIfIOSDevice() {
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    }
    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator['standalone']);

    if (isIos() && !isInStandaloneMode()) {
      this.openSnackBar(this.installMessage, '');
    }
  }

  /**
   *  This function open toaster msg,
   * @method openSnackBar
   * @param message snackbar message.
   * @param action message type.
   */
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2500,
      panelClass: ['blue-snackbar']
    });
  }


}
