export const ValidationMessages = {
 passwordNotMatching: 'Entered Password is not matching!! Try Again',
 mustPresentPassword: 'You must include password.',
 mustPresentConfirmPassword: 'You must include confirm password.',
 emailRequired: 'E-mail address is required.',
 passwordRequired: 'Password is required.',
 courseNameRequired: 'Course name is required',
 fileStandardRequired: 'File standard is required.',
 folderNameRequired: 'Folder name is required',
 firstNameRequired: 'First name is required.',
 lastNameRequired: 'Last name is required',
 groupNameRequired: 'Group name is required.',
 groupValidityRequired: 'Group validity is required',
 userCountRequired: 'User count is required.',
 nameRequired: 'Name is required.',
 domainRequired: 'Domain is required.',
 validCourse: 'Please select valid course',
 validUser: 'Please select valid user',
 courseFileStatus: 'Scorm File Upload Pending ',
 courseUploadRequire: 'You have not uploaded any course file',
 validCourseSelect: 'Please select the valid course',
 validUserSelect: 'Please select the valid users',
 fillRegistrationForm: 'Please fill the registration form',
 itemAdded: 'Item added to cart',
 phone: 'Valid phone number is required',
 organizationNameRequired: 'Organization name is required',
 fillForm: 'Please fill the form',
 maxUser: 'Please enter max user',
 expiryDate: 'Please enter expiry date',
 couponName: 'Please enter coupon name',
 percentage:'Please enter percentage value',
 subscriptionNameRequired: 'subscription name is required',
 subscriptionDescRequired: 'subscription description is required',
 subscriptionPriceRequired: 'subscription price is required',
 subscriptionCurrencyRequired: 'subscription currency is required',
 ValidityRequired: 'subscription currency is required',
 };
