import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor() { }

 /**
  * setter for logged in user api data
  * @method handle
  * @param userData -  user data from api response
  */
  handle(userData) {
    this.set(userData);
  }

  /**
  * return logged in user token
  * @method getLoggedInUserToken
  */
  getLoggedInUserToken() {
    const JsonData = JSON.parse(this.get());
    if (JsonData) {
      const token = JsonData.apiToken;
      return token;
    }
    return 0;
  }

  /**
  * return logged in user id
  * @method getUserId
  */
  getUserId() {
      const JsonData = JSON.parse(this.get());
      const userId = JsonData.id;
      return userId;
  }


 /**
  * setter for logged in user api data
  * @method set
  * @param userData -  user data from api response
  */
  set(userData) {
    localStorage.setItem('userData', userData);
  }

 /**
  * getter for logged in user api data
  * @method get
  */
  get() {
    return localStorage.getItem('userData');
  }

  /**
  * return logged in user email
  * @method getLoggedInUserEmail
  */
  getLoggedInUserEmail() {
    const JsonData = JSON.parse(this.get());
    const username = JsonData.email;
    return username;
  }

 /**
  * remove the user data from localstorage
  * @method remove
  */
  async remove() {
    await localStorage.removeItem('userData');
    await localStorage.removeItem('sherpaClientId');
  }

  /**
  * check whther user is valid
  * @method isValid
  */
  isValid() {
    const userData = this.get();
    if (userData) {
        return true;
    }
    return false;
  }


  /**
  * check whether user is logged in
  * @method isUserLoggedIn
  */
  isUserLoggedIn() {
    const userData = this.get();
    if (userData) {
        return userData;
    }
    return 0;
  }

  /**
  * check if user is present in local storage
  * @method isUser
  */
  isUser() {
    const userData = this.get();
    if (userData) {
      return userData;
    }
    return 0;
  }

  /**
  * checks whether user logged in
  * @method loggedIn
  */
  loggedIn() {
    return this.isValid();
  }

  /**
  * returns the user role
  * @method isRole
  */
  isRole() {
    const userData = this.get();
    if (userData) {
      const userDataJson = JSON.parse(userData);
      return userDataJson.roleId;
    }
    return 0;
  }

 /**
  * returns the company id
  * @method getCompanyId
  */
  getCompanyId() {
    const userData = this.get();
    if (userData) {
      const userDataJson = JSON.parse(userData);
      return userDataJson.companyId;
    }
    return 0;
  }

  /**
  * returns the group id
  * @method getGroupId
  */
  getGroupId() {
    const userData = this.get();
    if (userData) {
      const userDataJson = JSON.parse(userData);
      return userDataJson.groupId;
    }
    return 0;
  }

  /**
  * check is group admin allow to upload course
  * @method isAllowedGroupAdmin
  */
  isAllowedGroupAdmin() {
    const userData = this.get();
    if (userData) {
      const userDataJson = JSON.parse(userData);
      return userDataJson.isAllowedInternalCourse;
    }
    return 0;
  }

    /**
  * check is group admin allow to see leader board
  * @method isAllowedLeaderboard
  */
     isAllowedLeaderboard() {
      const userData = this.get();
      if (userData) {
        const userDataJson = JSON.parse(userData);
        return userDataJson.isAllowedLeaderboard;
      }
      return 0;
    }

}
