
import { MediaMatcher } from '@angular/cdk/layout';
import { Router, NavigationEnd   } from '@angular/router';
import {
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  ViewChild,
  HostListener,
  Directive,
  AfterViewInit,
  OnInit
} from '@angular/core';
import { MenuItems } from '../../shared/menu-items/menu-items';
import { AppHeaderComponent } from './header/header.component';
import { AppSidebarComponent } from './sidebar/sidebar.component';
import { AppBreadcrumbComponent } from './breadcrumb/breadcrumb.component';

import { PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { TokenService } from '../../core/services/token.service';
import { UtilServiceService } from '../../shared/services/util-service.service';
import { GroupAdminService } from '../../modules/group-admin/services/group-admin.service';

/** @title Responsive sidenav */
@Component({
  selector: 'app-full-layout',
  templateUrl: 'full.component.html',
  styleUrls: []
})

export class FullComponent implements OnDestroy, OnInit {
  mobileQuery: MediaQueryList;
  dir = 'ltr';
  green: boolean;
  blue: boolean;
  dark: boolean;
  minisidebar: boolean;
  boxed: boolean;
  danger: boolean;
  showHide: boolean;
  url: string;
  sidebarOpened;
  src: any = "assets/images/logo-light-icon.png";
  publicPage = false;
 public showSearch = false;

  public config: PerfectScrollbarConfigInterface = {};
  private _mobileQueryListener: () => void;
  groupDetails: any;
  loader: boolean;

  constructor(public router: Router,
             changeDetectorRef: ChangeDetectorRef,
             public utilService: UtilServiceService,
             private groupAdminService: GroupAdminService,
    media: MediaMatcher,
    public menuItems: MenuItems,
    public tokenService: TokenService
  ) {
    this.routeEvent(this.router);
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    const url = window.location.href;
    if ((url).includes('public')) {
      this.publicPage = true;
    }


  }


  routeEvent(router: Router) {
    router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        console.log(e);
      }
    });
  }

  

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  ngOnInit(): void {
    if (this.tokenService.isRole() === 4 || this.tokenService.isRole() === 5) {
      this.setAppearance();
    }
  }

   /**
   *  this function set the appearance for the dashboard according
   * to the domain name
   * @method setAppearance
   */

  setAppearance() {
    var appearanceSettings = JSON.parse(localStorage.getItem('appearanceSettings'));
    if(!appearanceSettings) {
      this.groupAdminService.getGroupDetail(this.tokenService.getGroupId()).subscribe((res: any) => {
        this.loader = false;
        appearanceSettings = res.Result;
        if(appearanceSettings.logo != "") {
          this.src = appearanceSettings.logo;
        }
        $(document).ready(() => {
          if (appearanceSettings) {
            if(appearanceSettings.logo != "") {
              this.src = appearanceSettings.logo;
            }
             const position = appearanceSettings.headerLogoPosition;
             // tslint:disable-next-line: triple-equals
              if (position === 'right') {
                $('.navbar-brand').addClass('justify-content-flex-end');
                $('.navbar-brand').removeClass('justify-content-center');
                $('.navbar-brand').removeClass('justify-content-flex-start');
                // tslint:disable-next-line: triple-equals
                } 
                else if (position === 'center') {
                  console.log("entered");
                $('.navbar-brand').addClass('justify-content-center');
                $('.navbar-brand').removeClass('justify-content-flex-end');
                $('.navbar-brand').removeClass('justify-content-flex-start');
                // tslint:disable-next-line: triple-equals
                } 
                else if (position ==='left') {
                 $('.navbar-brand').addClass('justify-content-flex-start');
                 $('.navbar-brand').removeClass('justify-content-flex-center');
                 $('.navbar-brand').removeClass('justify-content-flex-end');
                }
            }
          });
      }, (error: any) => {
        console.log(error);
      });
    }
    else {
      if(appearanceSettings.logo != "") {
        this.src = appearanceSettings.logo;
      }
      $(document).ready(() => {
        if (appearanceSettings) {
          if(appearanceSettings.logo != "") {
            this.src = appearanceSettings.logo;
          }
           const position = appearanceSettings.headerLogoPosition;
           // tslint:disable-next-line: triple-equals
            if (position === 'right') {
              $('.navbar-brand').addClass('justify-content-flex-end');
              $('.navbar-brand').removeClass('justify-content-center');
              $('.navbar-brand').removeClass('justify-content-flex-start');
              // tslint:disable-next-line: triple-equals
              } 
              else if (position === 'center') {
                console.log("entered");
              $('.navbar-brand').addClass('justify-content-center');
              $('.navbar-brand').removeClass('justify-content-flex-end');
              $('.navbar-brand').removeClass('justify-content-flex-start');
              // tslint:disable-next-line: triple-equals
              } 
              else if (position ==='left') {
               $('.navbar-brand').addClass('justify-content-flex-start');
               $('.navbar-brand').removeClass('justify-content-flex-center');
               $('.navbar-brand').removeClass('justify-content-flex-end');
              }
          }
        });
    }
  }
}
