import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PriceService {

  constructor() { }


  getPriceForManagementCourse(period: any, courseCount: any) {
    if (period === '1 Month') {
      // tslint:disable-next-line: max-line-length
      if (courseCount === '10') { return 86000; } else if (courseCount === '25') { return 115000; } else if (courseCount === '50') { return 144000; } else if (courseCount === '100') { return 172000; }
    } else if (period === '3 Months') {
      // tslint:disable-next-line: max-line-length
      if (courseCount === '10') { return 115000; } else if (courseCount === '25') { return 152000; } else if (courseCount === '50') { return 191000; } else if (courseCount === '100') { return 229000; }
    } else if (period === '6 Months') {
      // tslint:disable-next-line: max-line-length
      if (courseCount === '10') { return 144000; } else if (courseCount === '25') { return 191000; } else if (courseCount === '50') { return 240000; } else if (courseCount === '100') { return 288000; }
    } else if (period === '1 Year') {
      // tslint:disable-next-line: max-line-length
      if (courseCount === '10') { return 172000; } else if (courseCount === '25') { return 229000; } else if (courseCount === '50') { return 288000; } else if (courseCount === '100') { return 344000; }
    }

    return 0;
  }
  }
