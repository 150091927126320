import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { GroupAdminService } from '../../modules/group-admin/services/group-admin.service';
@Injectable({
  providedIn: 'root'
})
export class UtilServiceService {

  constructor(private groupAdminService: GroupAdminService,) { }

/**
 *  returns the next month 31days date
 * @method nextMonthDays
 * @return the next month 31days date
 */
  nextMonthDays() {
    const today = new Date();
    const nextMonthdays = new Date();
    nextMonthdays.setDate(today.getDate() + 31) ;
    return nextMonthdays;
  }

/**
 *  returns the date format eg. 2020-08-22
 * @method convertDate
 * @return the date format eg. 2020-08-22
 */
  convertDate(str) {
    const date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join('-');
  }


   /**
   *  this function set the appearance for the dashboard according
   * to the domain name
   * @method setAppearance
   */
  setAppearance() {
    var appearanceSettings = JSON.parse(localStorage.getItem('appearanceSettings'));

    if(!appearanceSettings) {
        this.groupAdminService.getAppearanceSettings(window.location.hostname).subscribe((res: any) => { 
          appearanceSettings = res.Result
        }, (error: any) => {
          console.log(error);
  
        });
    }
    $(document).ready(() => {

    if (appearanceSettings) {

       $('button.mat-button-base').css({
        'background': appearanceSettings.buttonBackgroundColor,
        'color' : appearanceSettings.buttonTextColor
       });

      $('#snav ~ .mat-drawer-content').css({
          background: appearanceSettings.backgroundColor,
       });

      $('.mat-toolbar.mat-primary').css({
          backgroundColor: appearanceSettings.headerBackgroundColor,
          position : 'left'
       });
      }
    });
  }

   /**
   *  conver the date format
   * @method replaceTime
   */
  replaceTime(dateTime) {
    if (dateTime) {
       let dateTime1 = moment(dateTime, 'DD/MM/YYYY hh:mm:ss A').format();
       if (dateTime1 === 'Invalid date') {
          dateTime1 = moment(dateTime, 'YYYY-MM-DD hh:mm:ss').format();
       }
       dateTime = dateTime1;
       return dateTime;
    }
  }
  replaceExpiryDate(dateTime) {
    if (dateTime) {
       let dateTime1 = moment(dateTime, 'DD/MM/YYYY').format();
       if (dateTime1 === 'Invalid date') {
          dateTime1 = moment(dateTime, 'YYYY-MM-DD').format();
       }
       dateTime = dateTime1;
       return dateTime;
    }
  }

}
