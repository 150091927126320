import { Component, OnInit, Inject } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { CreateAdminDialogComponent } from './dialogs/createEdit/createEdit.dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-admins',
  templateUrl: './admins.component.html',
  styleUrls: ['./admins.component.scss']
})
export class AdminsComponent implements OnInit {
  buttonConfig: any;
  loader = false;
  constructor(@Inject(DOCUMENT) private document: Document,
              public dialog: MatDialog,
              public snackBar: MatSnackBar) {

  }

/**
 *  opens the dialog box to create new master admin
 * @method openDialog
 */
openDialog(): void {
  const dialogRef = this.dialog.open(CreateAdminDialogComponent, {
    width: '550px',
  });
  dialogRef.afterClosed().subscribe(result => {
        // this.document.location.reload();
  });
  }

  ngOnInit(): void {
    this.buttonConfig = environment.button;
  }

}
