import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PublicService {

  constructor(private http: HttpClient) { }

  /**
  * login api call
  * @method login
  * @param loginData login api data
  */
  login(loginData) {
    return this.http.post('login', loginData);
  }

  /**
  * register api call
  * @method register
  * @param registrationData register api data
  */
  register(registrationData) {
    return this.http.post('register', registrationData);
  }

  /**
  * forget password api call
  * @method forgetPassword
  * @param formData api data
  */
  forgetPassword(formData) {
    return this.http.post('forgetPassword', formData);
  }

  /**
  * reset password api call
  * @method resetPassword
  * @param formData api data
  */
  resetPassword(formData) {
    return this.http.post('resetPassword', formData);
  }

  /**
  * get appearance data
  * @method getAppearanceSettings
  * @param domain api data
  */
  getAppearanceSettings(domain) {
    return this.http.get('appearanceSettings?domain=' + domain );
  }

  getActiveSubscriptionList() {
    return this.http.get('active_subscriptions');
  }

  getPackageDetails (id: number ) {
    return this.http.get('subscriptions/' + id);
  }

  createRazorPayOrderId(data) {
    return this.http.post('generate_order',data);
  }

  purchaseSubscription(data: any) {
    return this.http.post('subscription_purchase', data);
  }

  purchaseRequest(data) {
   return this.http.post('purchase_requests', data);
  }

  checkEmailExists(email) {
    return this.http.get('checkIfUserIsRegistered?email=' + email);
  }
  getListOfActiveCoupons() {
    return this.http.get('active_coupons');
  }
}
