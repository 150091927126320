import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import {GlobalVariables} from '../../../../../../../environments/globalVariable';
import { ValidationMessages } from '../../../../../../shared/messages';
import { PublicService } from '../../../../services/public.service';


@Component({
  selector: 'app-team-details',
  templateUrl: './team-details.component.html',
  styleUrls: ['./team-details.component.scss']
})
export class TeamDetailsComponent implements OnInit {

  form!: FormGroup;                         /** form-group for form */
  validationMessage: any;                   /** validation message */
  loader: boolean;                          /** loader variable */

  constructor(private fb: FormBuilder,
    private publicService: PublicService,
    public router: Router,
    public snackBar: MatSnackBar) {
    this.validationMessage = ValidationMessages;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      // tslint:disable-next-line: max-line-length
      email: new FormControl(null, [Validators.required, Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')]),
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      phone: [null, [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]],
      organizationName: [null, Validators.required]
    });
  }

   /**
   *  This function submits the form and creates purchase request.
   * @method submit
   */
  submit() {
    if (this.form.invalid) {
      this.openSnackBar(ValidationMessages.fillRegistrationForm, 'Error');
      return;
    }
    const data = {
      courses: GlobalVariables.subscriptionRequestData.courses,
      users: GlobalVariables.subscriptionRequestData.users,
      package: GlobalVariables.subscriptionRequestData.package,
      firstName: this.form.value.firstName,
      lastName: this.form.value.lastName,
      email: this.form.value.email,
      phone: String(this.form.value.phone),
      organization: this.form.value.organizationName,
      validity: GlobalVariables.subscriptionRequestData.validity
    };
    this.loader = true;
    this.publicService.purchaseRequest(data).subscribe((res: any) => {
      this.loader = false;
      this.router.navigate(['public/thank-you']);
    }, (error: any) => {
      console.log(error);
      this.openSnackBar(error.error.Comments, 'Error');
      this.loader = false;
    });
  }

  /**
   *  This function opens toaster message,
   * @method openSnackBar
   * @param message snackbar message.
   * @param action message type.
   */
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
      panelClass: ['blue-snackbar']
    });
  }
}
