import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { ValidationMessages } from '../../../../../../shared/messages';
import { PublicService } from '../../../../services/public.service';
import { PriceService } from '../price-services/price.service';
import { GlobalVariables } from '../../../../../../../environments/globalVariable';
import { environment } from '../../../../../../../environments/environment';


const price = new Map();
price.set(1, 800);
price.set(2, 700);
price.set(3, 600);
price.set(4, 500);
price.set(5, 400);


@Component({
  selector: 'app-team-course-packages',
  templateUrl: './team-course-packages.component.html',
  styleUrls: ['./team-course-packages.component.scss']
})

export class TeamCoursePackagesComponent implements OnInit {
  loader: boolean;                                                  /** loader variable */
  activeSubscriptionList: any = [];                                 /** array of subscription courses */
  form: FormGroup;                                                  /** registration form */
  statusSettings: any;                                              /** razorpay status settings */
  activeCoursePackages: any = [];                                   /** active course packages  */
  selectedPeriod: any;                                              /** default validity of course package */
  selectedCourses: any;                                             /** default course count  */
  users = 0;                                                        /** no of users selected for course package */
  showProperty = false;                                             /** flag for showing course count and validity options in form */
  priceId: number;                                                  /** priceId for mapping different price in spoken english course*/
  spokenEnglishCoursePrice = 0;                                     /** price for spoken english course */
  totalPrice = 0;                                                   /** total price to be paid */
  selectedItems: any;                                               /** items selected in multi-select */
  managementCoursePrice = 0;                                        /** management course package price */
  selectedPrice:number =0;                                           /** temp variable for calculation  */
  validityPeriod: number = 0;                                       /** validity period selected for course package */
 
  constructor(public router: Router,
    private priceService: PriceService,
    public snackBar: MatSnackBar,
    private fb: FormBuilder,
    private publicService: PublicService) {

    this.statusSettings = {
      disabled: true,
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      allowSearchFilter: true,
      unSelectAllText: 'UnSelect All',
      };
      this.users=0;
      this.selectedPrice=0;
      this.validityPeriod=12;
      this.selectedPeriod = '1 Year';
    
  }

  ngOnInit(): void {
    this.getActiveSubscriptionListApi();
    this.form = new FormGroup({
      userCount: new FormControl(null, [Validators.required, Validators.max(500)]),
      coursePackage: new FormControl([], Validators.required),
      // courseCount: new FormControl(100, Validators.required),
      coursePeriod: new FormControl('1 Year', Validators.required)
    });
  }

  /**
   *  This function fetches active subscription from Api
   * @method getActiveSubscriptionListApi
   */
  getActiveSubscriptionListApi() {
    this.loader = true;
    this.publicService.getActiveSubscriptionList().subscribe((res: any) => {
      for (const item of res.Result) {
          this.activeSubscriptionList.push(item);
          this.activeCoursePackages.push({
              id: item.id,
              name: item.name,
              price: item.price
            });
          
      }
      this.loader = false;
      console.log(this.activeCoursePackages);
    }, (error: any) => {
      console.log(error);
      this.loader = false;
    });
  }

  /**
   *  This function opens toaster message,
   * @method openSnackBar
   * @param message snackbar message.
   * @param action message type.
   */
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
      panelClass: ['blue-snackbar']
    });
  }

  /**
   *  This function fetches active subscription from Api
   * @method getActiveSubscriptionListApi
   */
  getStarted() {
    if (!this.form.value.userCount) {
      this.openSnackBar(ValidationMessages.fillForm, 'Error');
      return;
    }
    GlobalVariables.subscriptionRequestData = {
      // courses: Number(this.form.value.courseCount),
      courses:this.selectedItems.length,
      users: this.form.value.userCount,
      package: this.form.value.coursePackage,
      validity: this.form.value.coursePeriod
    };
    this.router.navigate(['public/team-details']);
  }

  download(id) {
    window.open(environment.api_base_url + 'subscriptions/' + id + '/downloadCourses');
  }

  /**
   *  item deselect multi-select
   * @method onItemDeSelect
   */
  onItemDeSelect(item: any) {

    let obj = this.activeCoursePackages.find((x: any) => x.id === item.id);
    if(obj.name==="Spoken English Course"){
      this.spokenEnglishCoursePrice=0;
    }else{
      this.selectedPrice -= obj.price;
    }
    
    // this.showProperty = true;
    this.calculateTotalPrice();
    console.log(item.id);
    if(this.selectedItems.length ===0 || 
      (this.selectedItems.length ===1 && this.spokenEnglishCoursePrice !==0)){
      this.showProperty = false;
      this.validityPeriod=12;
      // this.form.controls['coursePeriod'].reset();
      this.form.patchValue({
        coursePeriod: '1 Year',
      });
    
      // this.form.value.coursePeriod='1 Year';
    }
   
  }

  /**
  *  item select multi-select
  * @method onItemSelect
  */
  onItemSelect(item: any) {
    if (!this.form.value.userCount) {
      this.openSnackBar('Enter users count', 'Error');
      this.selectedItems = '';
      return;
    }
    
    let obj = this.activeCoursePackages.find((x: any) => x.id === item.id);
    if(obj.name==="Spoken English Course"){
      this.spokenEnglishCoursePrice=obj.price;
    }else{
    this.selectedPrice += obj.price;
    this.showProperty = true;
  }
    this.calculateTotalPrice();
    // console.log(item.id);
    // console.log(this.selectedItems);
  }  

    // if (item.name === '100 management / behavioral / functional courses') {
    //   this.showProperty = true;
    //   this.managementCoursePrice = 344000;
    //   this.totalPrice += this.managementCoursePrice;
    // }
    // if (item.name === 'Spoken English Course') {
    //   if (this.form.value.coursePackage.length === 1 ) {
    //     this.form.value.coursePeriod = '6 Months';
    //   }
    //   this.spokenEnglishCoursePrice += (this.form.value.userCount * price.get(this.priceId));
    //   this.totalPrice += this.spokenEnglishCoursePrice;
    // }
  

  /**
   *  This function selects all the items.
   * @method onSelectAll
   */
  onSelectAll(item: any) {
    console.log(item);
    this.selectedPrice=0;
    for (let activeCoursePackages of this.activeCoursePackages){
      if(activeCoursePackages.name === "Spoken English Course" ){
        this.spokenEnglishCoursePrice= activeCoursePackages.price; 
      }else{
        this.selectedPrice += activeCoursePackages.price;
      }
    }
    this.showProperty = true;
    this.calculateTotalPrice();
    console.log(this.selectedPrice);
  }

  /**
   *  This function deselects all the items.
   * @method onDeSelectAll
   */
  onDeSelectAll(item: any) {
    this.spokenEnglishCoursePrice=0;
    this.totalPrice = 0;
    this.selectedPrice=0;
    // this.form.reset();
    this.validityPeriod=12;
    this.showProperty = false;
    // this.form.controls['coursePeriod'].reset();
    this.form.patchValue({
      coursePeriod: '1 Year',
    });
  }

  /**
   *  This function maps price id with price after selecting users count.
   * @method onChange
   */
  onChange() {
    this.users=this.form.value.userCount;
    this.calculateTotalPrice();
  }

  /**
   *  This function sets total price based on course package selection.
   * @method setPrice
   */
  setPrice(item: any) { 
    let month: number=0;
    switch(item.value){
      case "1 Month": { 
        this.validityPeriod=1;
        break; 
     } 
     case "3 Months": { 
        this.validityPeriod=3; 
        break; 
     } 
     case "6 Months": { 
      this.validityPeriod=6; 
      break; 
   } 
   case "1 Year": { 
    this.validityPeriod=12;
    break; 
 } 
    }
    this.calculateTotalPrice();
    console.log(this.totalPrice);
    console.log(item.value);
    // this.managementCoursePrice = 0;
    // this.managementCoursePrice += this.priceService.getPriceForManagementCourse(this.form.value.coursePeriod, this.form.value.courseCount);
    // this.totalPrice = (this.selectedPrice) + this.spokenEnglishCoursePrice;
  }

  /**
   *  This function calculate total price.
   * 
   * 
   * @method calculateTotalPrice
   */  
  calculateTotalPrice(){
    this.totalPrice = this.validityPeriod* this.users* this.selectedPrice;
    this.totalPrice= this.totalPrice+ (this.spokenEnglishCoursePrice * this.users);
  }


}
