<div class="main-container">
  <mat-toolbar color="primary" class="topbar telative">
    <div class="navbar-header">
      <a class="navbar-brand" href="/">
        <b>
          <img
            src="assets/images/logo-icon.png"
            alt="homepage"
            class="dark-logo"
            style="width: auto; height: 48px"
          />

          <img
            [src]="src"
            alt="homepage"
            class="light-logo"
            style="width: auto; height: 48px"
          />
        </b>

        <span fxShow="false" fxShow.gt-xs>
          <img
            src="assets/images/logo-text.png"
            alt="homepage"
            class="dark-logo"
            style="width: 136px; height: 48px"
          />

          <img
            src="assets/images/logo-light-text.png"
            class="light-logo d-none"
            alt="homepage"
            style="width: 136px; height: 48px"
          />
        </span>
      </a>
    </div>

    <span fxFlex></span>

    <div style="width: 40%">
      <div class="header-img" style="width: fit-content">
        <div>
          <button [matMenuTriggerFor]="profile" mat-icon-button>
            <img
              src="assets/images/avatar.png"
              alt="user"
              class="profile-pic"
              style="margin-left: 30px"
            />
          </button>
          <p class="email-text" style="margin: 0px; white-space: initial">
            {{ userEmail }}
          </p>
        </div>

        <mat-menu #profile="matMenu" class="mymegamenu">
          <a mat-menu-item routerLink="/public/support">Support </a>
          <mat-divider></mat-divider>
          <button mat-menu-item (click)="logout()">
            <mat-icon>exit_to_app</mat-icon> Sign Out
          </button>
        </mat-menu>
      </div>

      <div
        style="
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        "
        class="header-contents"
      >
        <div>
          <a mat-button style="pointer-events: none; cursor: default">{{
            userEmail
          }}</a>
        </div>
        <span>|</span>
        <div><a mat-button routerLink="/public/support">Support </a></div>
        <span>|</span>
        <button mat-button (click)="logout()">Logout</button>
      </div>
    </div>
  </mat-toolbar>
  <div class="Main">
    <div>
      <mat-card style="min-height: 800px;">
        <mat-card-content style="margin-left: 80px; padding-top: 80px;">
          <div class="main">
            <mat-icon class="icon-style">business</mat-icon>
            <div class="content" style="margin-left: 20px;">
              <div>
                <h2 mat-title class="content-title"style="font-size:38px; margin-bottom:10px;" >Contact Us</h2>
              </div>
              <div class="sub-main" style="font-size: 19px; line-height: 30px;">
                <div mat-body>Mail us at: learn@onehourlearning.com</div>
                <!-- <div mat-body>Nishant Kumar: nishant@onehourlearning.com</div>
                <div mat-body>Sujith Maroli: maroli@onehourlearning.com</div> -->
              </div>
            </div>
          </div>

          <!-- <div class="main" style="margin-top: 30px;">
            <mat-icon class="icon-style">contact_phone</mat-icon>
            <div class="content" style="margin-left: 20px;">
              <div>
                <h2 mat-title class="content-title" style="font-size:38px; margin-bottom: 10px;">Call us at</h2>
              </div>
              <div class="sub-main" style="font-size: 19px; line-height: 30px;">
                <div mat-body>Delhi NCR: +91-9212100436</div>
                <div mat-body>Mumbai: +91-8451046147</div>
                <div mat-body>Bengaluru: +91-9866011312</div>
                <div mat-body>Chennai: +91-9137866823</div>
              </div>
            </div>
          </div> -->
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
