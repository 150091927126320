import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { PublicService } from '../../../../services/public.service';
import {GlobalVariables} from '../../../../../../../environments/globalVariable';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ValidationMessages } from '../../../../../../shared/messages';
import { environment } from '../../../../../../../environments/environment';

@Component({
  selector: 'app-course-packages',
  templateUrl: './course-packages.component.html',
  styleUrls: ['./course-packages.component.scss']
})

export class CoursePackagesComponent implements OnInit {

  loader: boolean;                                                  /** loader variable */
  activeSubscriptionList: any = [];                                 /** array of subscription courses */

  constructor(public router: Router,
    public snackBar: MatSnackBar,
    private publicService: PublicService) { }

  ngOnInit(): void {
    this.getActiveSubscriptionListApi();
  }

  /**
   *  This function fetches active subscription from Api
   * @method getActiveSubscriptionListApi
   */
  getActiveSubscriptionListApi() {
    this.loader = true;
    this.publicService.getActiveSubscriptionList().subscribe((res: any) => {
      for (const item of res.Result) {
        if (item.id !== 1) {                                        /** temporary case due to inappropriate data received from api */
          this.activeSubscriptionList.push(item);
        }
      }
      this.loader = false;
    }, (error: any) => {
      console.log(error);
      this.loader = false;
    });
  }

  /**
   *  This function navigates to the cart for buying the package,
   * @method goToCart
   * @param packageId package id.
   */
  goToCart(item: any) {
    if (item.name === 'Book Summaries') {
      this.openSnackBar('Coming Soon', 'Success');
      return ;
    }
    const queryParams: any = {};
    queryParams.coursePackageId = JSON.stringify(item.id);
    this.router.navigate(['public/cart'], { queryParams });
  }

  /**
   *  This function opens toaster message,
   * @method addToCart
   * @param item item to be added to cart.
   */
  addToCart(item: any) {

    if (item.name === 'Book Summaries') {
      this.openSnackBar('Coming Soon', 'Success');
      return ;
    }
    if (GlobalVariables.cartItems.indexOf(item) === -1) {
      GlobalVariables.cartItems.push(item);
      this.openSnackBar(ValidationMessages.itemAdded, 'Success');
    }
  }

  download(id) {
    window.open(environment.api_base_url + 'subscriptions/' + id + '/downloadCourses');
  }

  /**
   *  This function opens toaster message,
   * @method openSnackBar
   * @param message snackbar message.
   * @param action message type.
   */
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
      panelClass: ['blue-snackbar']
    });
  }
}
