import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

import { ValidationMessages } from '../../../../../../shared/messages';
import { PublicService } from '../../../../services/public.service';
import { RazorpayService } from '../../../../services/razorpay.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {GlobalVariables} from '../../../../../../../environments/globalVariable';
declare let Razorpay: any;

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

  selectedCoursePackages: any = [];         /** array of selected course packages */
  form!: FormGroup;                         /** form-group for form */
  validationMessage: any;                   /** validation message */
  subTotal = 0;                             /** subtotal amount of the cart */
  couponAmount = 0;                         /** coupon amount to be deducted from aub-total */
  totalPayable = 0;                         /** total amount to be paid */
  loader: boolean;                          /** loader variable */
  response: any;                            /** response received from razorpay */
  couponCode = {id: 0, name: '' , percentageValue: 0  };                           /** coupon code */
  subscriptionIds: any = [];                /** array of subscription ids */
  showPassword = false;
  activeCoupons: any;
  couponForm: FormGroup;

  constructor(public route: ActivatedRoute,
    private fb: FormBuilder,
    private publicService: PublicService,
    public snackBar: MatSnackBar,
    public router: Router,
    private razorpayService: RazorpayService, private cd: ChangeDetectorRef) {
      this.validationMessage = ValidationMessages;
    const coursePackageId = JSON.parse(this.route.snapshot.queryParamMap.get('coursePackageId'));
    if (coursePackageId != null) {
      this.getPackageDetailsApi(coursePackageId);
    }
  }

  ngOnInit(): void {
    this.getActiveCoupons();
   this.selectedCoursePackages = GlobalVariables.cartItems;
   this.calculateSubTotalAmount();
   this.calculateTotalPayable();
   this.getSubscriptionIds();
    this.razorpayService.lazyLoadLibrary('https://checkout.razorpay.com/v1/checkout.js').subscribe();
    this.form = this.fb.group({
      email: new FormControl(null, [Validators.required, Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')]),
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      password: [null, Validators.required],
      confirmPassword: [null, Validators.required]
    });
    this.couponForm = this.fb.group({
      coupon: new FormControl(null),
    });
    (<any>$(document)).ready(() => {
      $('.mat-drawer-content').css({
        'background': '#fff',
        'overflow': 'hidden'
      });
    });
  }

  // tslint:disable-next-line: member-ordering
  RAZORPAY_OPTIONS = {
    'key': 'rzp_test_bRZ4zBQNlwdYl8',
    'name': 'One Hour Learning',
    'order_id': '',
    'description': 'Load Wallet',
    'image': 'assets/images/logo-icon.png',
    'prefill': {
      'email': '',
      'contact': '',
      'method': ''
    },
    'theme': {
      'color': '#0096C5'
    }
  };

  /**
   *  This function initiates payment through razorpay,
   * @method proceedToPay
   */
  proceedToPay() {
    console.log(this.form);
    if (this.form.invalid) {
      this.openSnackBar(ValidationMessages.fillRegistrationForm, 'Error');
      return;
    } else {
      const price = this.totalPayable * 100;
      const data = {
        price:  price,
        currency: 'INR'
      };
      this.loader = true;
      this.publicService.createRazorPayOrderId(data).subscribe((res: any) => {
        this.loader = false;
        this.RAZORPAY_OPTIONS.order_id = res.Result.id;
        this.RAZORPAY_OPTIONS.prefill.email = this.form.value.email;
        // tslint:disable-next-line: max-line-length
        this.RAZORPAY_OPTIONS['handler'] = this.razorPaySuccessHandler.bind(this);  /**binding this object to both success and dismiss handler */
        const razorpay = new Razorpay(this.RAZORPAY_OPTIONS);
        razorpay.open();
      }, (error: any) => {
        console.log(error);
        this.loader = false;
      });
   }
  }

  /**
   *  This function handles the success response,
   * @method razorPaySuccessHandler
   */
  razorPaySuccessHandler(response: any) {
    this.loader = true;
    const data = {
      firstName: this.form.value.firstName,
      lastName: this.form.value.lastName,
      email: this.form.value.email,
      password: this.form.value.password,
      couponId: this.couponCode.id,
      price: this.totalPayable,
      currency: 'INR',
      subscriptionIds: this.subscriptionIds,
      razorpayPaymentId: response.razorpay_payment_id,
      razorpayOrderId: response.razorpay_order_id,
      razorpaySignature: response.razorpay_signature
    };
    this.publicService.purchaseSubscription(data).subscribe((res: any) => {
      this.loader = false;
      this.router.navigate(['public/thank-you/successful-purchase']);
    }, (error: any) => {
      this.openSnackBar( error.Comments, 'Error');
      this.loader = false;
    });
    this.cd.detectChanges();
  }

  /**
   *  This function removes item from cart,
   * @method calculateSubTotalAmount
   */
  calculateSubTotalAmount() {
    for (const item of this.selectedCoursePackages) {
      this.subTotal += item.price;
    }
  }

  /**
   *  This function removes item from cart,
   * @method removeFromCart
   * @param item selected course package.
   */
  removeFromCart(item: any) {
    const index = this.selectedCoursePackages.indexOf(item);
    if (index > -1) {
      this.subTotal -= this.selectedCoursePackages[index].price;
      this.selectedCoursePackages.splice(index, 1);
    }
    this.calculateTotalPayable();
    this.getSubscriptionIds();

    this.router.navigate([], {
      queryParams: {
        'coursePackageId': null
      },
      queryParamsHandling: 'merge'
    });
  }

  /**
  *  This function calculates total payable amount,
  * @method calculateTotalPayable
  * @param id id of the package.
  */
  calculateTotalPayable() {
    this.totalPayable = this.subTotal - this.couponAmount;
  }

  /**
   *  This function fetches subscription details,
   * @method getPackageDetailsApi
   * @param id id of the package.
   */
  getPackageDetailsApi(id: number) {
    this.loader = true;
    this.publicService.getPackageDetails(id).subscribe((res: any) => {
      this.loader = false;
      this.selectedCoursePackages.push(res.Result);
      this.calculateSubTotalAmount();
      this.calculateTotalPayable();
      this.getSubscriptionIds();
    }, (error: any) => {
      console.log(error);
      this.loader = false;
    });
  }

  /**
   *  This function opens toaster message,
   * @method openSnackBar
   * @param message snackbar message.
   * @param action message type.
   */
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
      panelClass: ['blue-snackbar']
    });
  }

  /**
   *  This function adds subscription ids to the array,
   * @method getSubscriptionIds
   */
  getSubscriptionIds() {
    for (const item of this.selectedCoursePackages) {
      this.subscriptionIds.push(item.id);
    }
  }

  checkEmail() {
    this.loader = true;
    console.log('ss');
    this.publicService.checkEmailExists(this.form.value.email).subscribe((res: any) => {
      console.log("result", res);
      this.loader = false;
      if (res.Result.isExist === true) {
        this.showPassword = true;
      }
    }, (error: any) => {
      console.log(error);
      this.loader = false;
    });
  }

  getActiveCoupons() {
    this.loader = true;
    this.publicService.getListOfActiveCoupons().subscribe((res: any) => {
      this.loader = false;
    this.activeCoupons = res.Result;
    }, (error: any) => {
      console.log(error);
      this.loader = false;
    });
  }

  ApplyCoupon() {
    let isValidCoupon = false;
    for (const item of this.activeCoupons) {
      if (item.name === this.couponForm.value.coupon) {
        this.couponCode = item;
        this.couponAmount = item.percentageValue * this.subTotal / 100;
        this.calculateTotalPayable();
        isValidCoupon = true;
      }
    }
    if (isValidCoupon === false) {
      this.openSnackBar('invalid Coupon', 'Error');
    }


  }
}
