<app-header></app-header>

<div class="main">
  <div class="page-section" id="home">
    <div class="row m-0">
      <div class="col-md-6 offset-sm-6">
        <div class="home-text">
          <h1>
            Sharpen <br />
            your skills
          </h1>
        </div>
        <ul class="list-text p-0">
          <li>Readymade online learning courses</li>
          <li>Learning management system (LMS)</li>
          <li>Customized content development</li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="course-list page-section" id="course-list">
  <div class="row m-0">
    <div class="col-md-6 offset-sm-6">
      <div class="home-text"></div>
      <ul class="list-text">
        <li>300+ readymade courses</li>
        <li>Fully animated and interactive</li>
        <li>Plug & play – seamless integration on any LMS</li>
        <li>250+ management book summaries</li>
        <li>
          <a href="/course-library">Click here to browse course library </a>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="course-demo page-section" id="course-demo">
  <div class="container-fluid">
    <div class="row justify-content-between m-0 p-3">
      <div>
        <span>
          <iframe
            src="https://player.vimeo.com/video/257528764"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
          ></iframe>
        </span>

        <span style="margin-left: -35px" class="second-video">
          <iframe
            src="https://player.vimeo.com/video/257528846"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
            class="mx-5"
          ></iframe>
        </span>
      </div>
      <div>
        <span style="margin-right: -33px">
          <iframe
            src="https://player.vimeo.com/video/257528918"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
          ></iframe>
        </span>

        <span class="second-video">
          <iframe
            src="https://player.vimeo.com/video/257529728"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
            class="ml-5"
          ></iframe>
        </span>
      </div>
    </div>
    <div class="row justify-content-between m-0 p-3">
      <div>
        <iframe
          src="https://player.vimeo.com/video/257887817"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
        ></iframe>

        <span style="margin-left: -35px" class="second-video">
          <iframe
            src="https://player.vimeo.com/video/257887831"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
            class="mx-5"
          ></iframe>
        </span>
      </div>
      <div>
        <span style="margin-right: -33px">
          <iframe
            src="https://player.vimeo.com/video/257887863"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
          ></iframe>
        </span>
        <span class="second-video">
          <iframe
            src="https://player.vimeo.com/video/275011633"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
            class="ml-5"
          ></iframe>
        </span>
      </div>
    </div>
    <div class="row justify-content-between m-0 p-3">
      <div>
        <iframe
          src="https://player.vimeo.com/276077923/48b7bf7e46"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
        ></iframe>

        <span style="margin-left: -35px" class="second-video">
          <iframe
            src="https://player.vimeo.com/406103134/dc08df33ff"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
            class="mx-5"
          ></iframe>
        </span>
      </div>
      <div>
        <span style="margin-right: -33px">
          <iframe
            src="https://player.vimeo.com/148722894/df060e180dn"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
          ></iframe>
        </span>

        <span class="second-video">
          <iframe
            src="https://player.vimeo.com/405728293/f9ab38eb68"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
            class="ml-5"
          ></iframe>
        </span>
      </div>
    </div>
  </div>
</div>

<div class="our-clients page-section" id="our-clients">
  <div class="container-fluid">
    <div class="row m-0">
      <div class="col-md-12 text-center">
        <h1 class="p-5">
          Trusted by 200+ corporate clients all over the world
        </h1>
        <div class="logo-container">
          <div>
            <img
              src="../../../../../assets/logo/levis.png"
              alt="logo"
              class="img-fluid"
            />
          </div>
          <div>
            <img
              src="../../../../../assets/logo/indogo.png"
              alt="logo"
              class="img-fluid"
            />
          </div>
          <div>
            <img
              src="../../../../../assets/logo/honda.png"
              alt="logo"
              class="img-fluid"
            />
          </div>
          <div>
            <img
              src="../../../../../assets/logo/abbott.png"
              alt="logo"
              class="img-fluid"
            />
          </div>
          <div>
            <img
              src="../../../../../assets/logo/bayer.png"
              alt="logo"
              class="img-fluid"
            />
          </div>
          <div>
            <img
              src="../../../../../assets/logo/siemens.png"
              alt="logo"
              class="img-fluid"
            />
          </div>
          <div>
            <img
              src="../../../../../assets/logo/grofers.png"
              alt="logo"
              class="img-fluid"
            />
          </div>
        </div>
        <div class="logo-container">
          <div>
            <img
              src="../../../../../assets/logo/larsen-and-toubro.png"
              alt="logo"
              class="img-fluid"
            />
          </div>
          <div>
            <img
              src="../../../../../assets/logo/vodafone.png"
              alt="logo"
              class="img-fluid"
            />
          </div>
          <div>
            <img
              src="../../../../../assets/logo/domino.png"
              alt="logo"
              class="img-fluid"
            />
          </div>
          <div>
            <img
              src="../../../../../assets/logo/lenovo.png"
              alt="logo"
              class="img-fluid"
            />
          </div>
          <div>
            <img
              src="../../../../../assets/logo/fiat.png"
              alt="logo"
              class="img-fluid"
            />
          </div>
          <div>
            <img
              src="../../../../../assets/logo/cams.png"
              alt="logo"
              class="img-fluid"
            />
          </div>
          <div>
            <img
              src="../../../../../assets/logo/sun_life.png"
              alt="logo"
              class="img-fluid"
            />
          </div>
        </div>
        <div class="logo-container">
          <div>
            <img
              src="../../../../../assets/logo/legrand.png"
              alt="logo"
              class="img-fluid"
            />
          </div>
          <div>
            <img
              src="../../../../../assets/logo/rockeill-automation.png"
              alt="logo"
              class="img-fluid"
            />
          </div>
          <div>
            <img
              src="../../../../../assets/logo/jk-cements.png"
              alt="logo"
              class="img-fluid"
            />
          </div>
          <div>
            <img
              src="../../../../../assets/logo/john-dreere.png"
              alt="logo"
              class="img-fluid"
            />
          </div>
          <div>
            <img
              src="../../../../../assets/logo/times-internet.png"
              alt="logo"
              class="img-fluid"
            />
          </div>
          <div>
            <img
              src="../../../../../assets/logo/radio_mirchi.png"
              alt="logo"
              class="img-fluid"
            />
          </div>
          <div>
            <img
              src="../../../../../assets/logo/iifl.png"
              alt="logo"
              class="img-fluid"
            />
          </div>
        </div>
        <div class="logo-container">
          <div>
            <img
              src="../../../../../assets/logo/welspun.png"
              alt="logo"
              class="img-fluid"
            />
          </div>
          <div>
            <img
              src="../../../../../assets/logo/cms-cgm.png"
              alt="logo"
              class="img-fluid"
            />
          </div>
          <div>
            <img
              src="../../../../../assets/logo/arvind.png"
              alt="logo"
              class="img-fluid"
            />
          </div>
          <div>
            <img
              src="../../../../../assets/logo/ask.png"
              alt="logo"
              class="img-fluid"
            />
          </div>
          <div>
            <img
              src="../../../../../assets/logo/bnp_paribas.png"
              alt="logo"
              class="img-fluid"
            />
          </div>
          <div>
            <img
              src="../../../../../assets/logo/dr_reddy.png"
              alt="logo"
              class="img-fluid"
            />
          </div>
          <div>
            <img
              src="../../../../../assets/logo/future-group.png"
              alt="logo"
              class="img-fluid"
            />
          </div>
        </div>
        <div class="logo-container">
          <div>
            <img
              src="../../../../../assets/logo/harman.png"
              alt="logo"
              class="img-fluid"
            />
          </div>
          <div>
            <img
              src="../../../../../assets/logo/amadeus.png"
              alt="logo"
              class="img-fluid"
            />
          </div>
          <div>
            <img
              src="../../../../../assets/logo/nayara.png"
              alt="logo"
              class="img-fluid"
            />
          </div>
          <div>
            <img
              src="../../../../../assets/logo/sterling_software.png"
              alt="logo"
              class="img-fluid"
            />
          </div>
          <div>
            <img
              src="../../../../../assets/logo/f-secure.png"
              alt="logo"
              class="img-fluid"
            />
          </div>
          <div>
            <img
              src="../../../../../assets/logo/inorbit.png"
              alt="logo"
              class="img-fluid"
            />
          </div>
          <div>
            <img
              src="../../../../../assets/logo/gaar.png"
              alt="logo"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>