<nav class="navbar navbar-expand-lg bg-transparent navbar-light fixed-top" id="navbar">
  <a class="navbar-brand" href="/"><img src="assets/img/logo.png" alt="logo" width="180" /></a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" aria-controls="navbarNavDropdown"
    aria-expanded="false" aria-label="Toggle navigation" id="navbarNavDropdown">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse">
    <ul class="navbar-nav ml-auto mt-5">
      <li class="nav-item">
        <a class="nav-link" href="#home">Home <span class="sr-only">(current)</span></a>
      </li>
      <!-- <li class="nav-item">
        <a class="nav-link" href="#course-list">Course</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#course-list">Course list</a>
      </li> -->
      <li class="nav-item">
        <a class="nav-link" href="#course-demo">Course demo</a>
      </li>
      <!-- <li class="nav-item">
        <a class="nav-link" href="course-packages">For Individuals</a>
      </li> -->
      <!-- <li class="nav-item">
        <a class="nav-link" href="team-course-packages">For Teams</a>
      </li> -->
      <li class="nav-item">
        <a class="nav-link" href="#our-clients">Our clients</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" *ngIf="loggedIn" style="cursor: pointer;" (click)="goTo()">Dashboard</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" *ngIf="!loggedIn" href="/public/login">Login</a>
        <a class="nav-link" *ngIf="loggedIn" (click)="logout()" style="cursor: pointer;">Logout</a>
      </li>
      <li class="nav-item">
       <a class="nav-link" href="/public/contact-us">Contact Us</a>
      </li>
      <!-- <li class="nav-item">
        <a class="nav-link" (click)="goToCart()" style="cursor: pointer;"><mat-icon>shopping_cart</mat-icon>
        </a>
      </li> -->
    </ul>
  </div>
</nav>
