import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { PublicComponent } from "./public.component";
import { LoginComponent } from "./components/login/login.component";
import { BeforeLoginService } from "./../../core/services/before-login.service";
import { ForgotPasswordComponent } from "./components/forget-password/forgot-password.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { HomeComponent } from "./components/home/home.component";
import { TermAndConditionsComponent } from "./components/term-and-conditions/term-and-conditions.component";
import { PrivacyPolicyComponent } from "./components/privacy-policy/privacy-policy.component";
import { CreditComponent } from "./components/credit/credit.component";
import { CourseLibraryComponent } from "./components/course-library/course-library.component";
import { CoursePackagesComponent } from "./components/subscription/individual/course-packages/course-packages.component";
import { CartComponent } from "./components/subscription/individual/cart/cart.component";
import { TeamCoursePackagesComponent } from "./components/subscription/team/team-course-packages/team-course-packages.component";
import { TeamDetailsComponent } from "./components/subscription/team/team-details/team-details.component";
import { PaymentSuccessfulComponent } from "./components/subscription/team/payment-successful/payment-successful.component";
import { ThankYouComponent } from "./components/subscription/team/thank-you/thank-you.component";
import { ThankYouIndividualComponent } from "./components/subscription/individual/thank-you-individual/thank-you-individual.component";
import { ContactUsComponent } from "./components/contact-us/contact-us.component";
import { SupportsComponent } from "./components/supports/supports.component";
import { MobileContactUsComponent } from "./components/mobile-contact-us/mobile-contact-us.component";
// import { HomeComponent } from './components/home/home.component';
// import { ContactUsComponent } from './components/contact-us/contact-us.component';

const routes: Routes = [
  {
    path: "",
    component: PublicComponent,
    //   redirectTo: 'library',
    children: [
      {
        path: "login",
        component: LoginComponent,
        // canActivate: [BeforeLoginService],
      },
      {
        path: "forgot-password",
        component: ForgotPasswordComponent,
      },
      {
        path: "reset-password",
        component: ResetPasswordComponent,
      },
      {
        path: "",
        component: HomeComponent,
      },
      {
        path: "contact-us",
        component: ContactUsComponent,
      },
      {
        path: "terms-condition",
        component: TermAndConditionsComponent,
      },

      {
        path: "privacy-policy",
        component: PrivacyPolicyComponent,
      },

      {
        path: "credit",
        component: CreditComponent,
      },
      {
        path: "course-library",
        component: CourseLibraryComponent,
      },
      {
        path: "course-packages",
        component: CoursePackagesComponent,
      },
      {
        path: "cart",
        component: CartComponent,
      },
      {
        path: "team-course-packages",
        component: TeamCoursePackagesComponent,
      },
      {
        path: "team-details",
        component: TeamDetailsComponent,
      },
      {
        path: "payment-success",
        component: PaymentSuccessfulComponent,
      },
      {
        path: "thank-you",
        component: ThankYouComponent,
      },
      {
        path: "thank-you/successful-purchase",
        component: ThankYouIndividualComponent,
      },

      {
        path: "support",
        component: SupportsComponent,
      },
      {
        path: "mobile-contact-us",
        component: MobileContactUsComponent
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PublicRoutingModule {}
