  


<app-header></app-header>

<div class="container jumbotron"  style="margin-top: 120px;">
    <h3>Privacy Policy</h3>
    <p class="intro">This Privacy Policy ("Privacy Policy") explains how this website <a
        href="http://www.onehourlearning.com">www.onehourlearning.com</a>, owned by Bensar Learning Pvt. Ltd.
      ("Onehourlearning," "we" or "us" or "Company") collect, use and share personally identifiable information of
      visitors of our web site (the "Site") and users of our products and services (the "Services"). Please note that the
      primary purpose of our Site and the Services it to allow you to enroll in and take online courses on a variety of
      topics ("Courses"). For the purpose of this Privacy Policy, information that you provide through the Site can be in
      one of two categories: information relating to your use of the Site or the Services, including, without limitation,
      enrollment information for Courses, is referred to as "Site Information," while information relating to taking the
      Courses, including, without limitation, interchanges with the Course instructors ("Instructors"), other learners
      ("Users"), answers, essays and other items submitted to satisfy Course requirements, is referred to as "Course
      Information." When this Privacy Policy uses the generic term "information" it is intended to address the general use
      of information, and not your specific Site Information or Course Information. By using the Site, Services, or
      Courses you consent to the collection, use and disclosure of your personally identifiable Site Information and
      Course Information, as applicable, in accordance with this Privacy Policy.</p>
    <h4>1. Personally Identifiable Information We Collect and How We Use It.</h4>
    <p>You can generally visit the Site without revealing any personally identifiable information about yourself. We do
      not collect personal information from our Site visitors without the Site visitor providing us with this information
      as set forth in this Privacy Policy.</p>
    <p>If you request to receive company or product information, or request information about specific Services, or
      provide comments about Services, you will be asked to provide contact information so that we can complete your
      request. We use this Site Information to fulfill your request. We may also use this Site Information to send you
      additional information about our Services or Courses on the Site that you may be interested in.</p>
    <p>Some of the Services require that you become a registered user and create a user account. This will require that
      you provide certain personally identifiable information, that may include (but not be limited to) your (and if you
      are enrolling as part of our Onehourlearning for Business program) your company's name, e-mail address, as well as
      telephone and address information. This personal information of yours may be provided to us directly by your Company
      with or without your knowledge/permission as part of the corporate membership program.</p>
    <p>You allow us to use this personally identifiable Site Information to register you to use the Services, enroll in
      Courses, and otherwise provide you with the Services; verify your authority to enter the Site and access the Courses
      and Services; notify you of updates; improve the content and general administration of the Site and the Services;
      provide you with notices regarding the Services and other services that you have purchased or may wish to purchase
      in the future.</p>
    <p>You also consent to our providing this personally identifiable information to Instructors as part of the Course
      Information when you enroll in a Course. Instructors will use this Course Information to provide the Course to you.
      Instructors may have other users for Course Information. It is your responsibility when you enroll in a Course to
      confirm what uses the Instructor will make of your personally identifiable Course Information. Onehourlearning does
      not control these uses by Instructors.</p>
    <h4>2. Other Information We Collect and How We Use It.</h4>
    <p>Onehourlearning may also automatically collect and analyze Site Information about your general usage of the Site,
      Services and Courses. We might track your usage patterns to see what features of the Site, Services and Courses you
      commonly use, Site traffic volume, frequency of visits, type and time of transactions, type of browser, browser
      language, IP address and operating system, and statistical information about how you use the Services and Courses.
      We only collect, track and analyze such Site Information in an aggregate manner that does not personally identify
      you. This aggregate data may be used to assist us in operating the Site and the Services and provided to other third
      parties to enable them to better understand the operation of the Services, and improve their Course offerings, but
      such information will be in aggregate form only and it will not contain personally identifiable data.</p>
    <p>Instructors may collect non-personally identifiable Course Information in connection with your participation in
      Courses. This Course Information may include, but not be limited to, your interaction with the Instructor and other
      Users, comments you leave on Course forums, answers or essays you submit to satisfy Course requirements, and so
      forth. As noted above, it is your responsibility to review the Instructor's policy in connection with all Course
      Information and decide whether or not you want to provide such Course Information.</p>
    <h4>3. Children.</h4>
    <p>We recognize the privacy interests of children and we encourage parents and guardians to take an active role in
      their children's online activities and interests. We do not knowingly collect personal information from children
      under 13. If we learn that we have collected personal information from a child under 13, we will delete such
      information. Children under the age of 13 may not register for an account or register for or purchase Courses.
      Individuals under the age of 18 may only use the Services with the involvement, supervision, and approval or a
      parent or legal guardian.</p>
    <h4>4. Disclosure of Information.</h4>
    <p>We will share your personally identifiable Site Information with third parties only in the ways that are described
      in this privacy policy. These include, but are not limited to, sharing Site Information with service providers to
      allow them to fulfill your requests, to Instructors (in which case this will become Course Information), and to your
      employer if you are enrolled through the Onehourlearning for Business program as an employee. We do not sell your
      personal information to third parties. Please refer to the sections above on personally identifiable and other
      Course Information for how Course Information may be used.</p>
    <p>We may also disclose your personally identifiable information: (a) if we are required to do so by law, regulation
      or other government authority or otherwise in cooperation with an investigation of a governmental authority, (b) to
      enforce these Terms of Use, or (c) to protect the safety of Users of our Site and our Services and Courses. In
      addition, we may transfer your personally identifiable information to a successor entity upon a merger,
      consolidation or other corporate reorganization.</p>
    <p>Vendors and Service Providers: We want to provide you with excellent service and make your Onehourlearning
      experience as seamless as possible. We share information, including nonpublic Personal Information, with third
      parties that perform services on our behalf so we can provide and improve our services. For example, we may disclose
      Personal Information for customer support by third parties. These third parties must abide by this Privacy Policy
      and keep nonpublic information confidential and use it only for its intended purpose.</p>
    <h4>5. Data Retention.</h4>
    <p>We retain Site Information and the personal data we process on behalf of Users for as long as needed to provide the
      Services. We will retain and use this personal Site Information as necessary to comply with our legal obligations,
      resolve disputes, and enforce our agreements with Users, and/or the period required by laws in applicable
      jurisdictions. Please see the sections above for how Course Information may be used.</p>
    <h4>6. Updating, Correcting and Deleting Your Information; Opt-out.</h4>
    <p>If you believe that Onehourlearning has incorrect Site Information or Course Information that was provided as part
      of the Services, you may use the Services to correct, amend, or delete that information. You may also contact us by
      e-mailing our customer support at <span id="cloak38255"><a
          href="mailto:contact@onehourlearning.com">contact@onehourlearning.com</a></span>
   . Alternatively, you may send a letter with your requested changes, corrections or deletions to the
      following address:
    </p>
    <p>BENSAR LEARNING PVT. LTD.</p>
    <p>E43/1, OKHLA PHASE 2</p>
    <p>SPRINGBOARD BUILDING</p>
    <p>NEW DELHI 110020</p>
    <p>From time to time, we may send you emails about Course or new or modified Services that we believe might interest
      you. If you wish to opt-out of receiving these materials, you may notify us at <span id="cloak67625"><a
          href="mailto:contact@onehourlearning.com">contact@onehourlearning.com</a></span>
    </p>
    <h4>7. Security.</h4>
    <p>The security of your personal information is important to us. Onehourlearning employs procedural and technological
      measures to protect your personally identifiable information. These measures are reasonably designed to help protect
      your personally identifiable information from loss, unauthorized access, disclosure, alteration or destruction. We
      may use software, secure socket layer technology (SSL) encryption, password protection, firewalls, internal
      restrictions and other security measures to help prevent unauthorized access to your personally identifiable
      information. However, Onehourlearning cannot guarantee that unauthorized third parties will never be able to defeat
      these measures or use your personally identifiable information for improper purposes. Therefore, you should be aware
      that when you voluntarily display or distribute personally identifiable information, that information could be
      collected and used by others. Onehourlearning is not responsible for the unauthorized use by third parties of
      information you post or otherwise make available publicly.</p>
    <h4>8. Links to Other Websites.</h4>
    <p>The Site, Services and in particular the Courses, may provide links to third party web sites or resources not
      associated with us and over which we do not have control ("External Web Sites"). Such links do not constitute an
      endorsement by Onehourlearning of the External Web Sites, the content displayed therein, or the persons or entities
      associated therewith. You acknowledge that Onehourlearning is only providing these links as a convenience, and
      cannot be responsible for the content of such External Web Sites.</p>
    <h4>9. Material Changes.</h4>
    <p>Onehourlearning reserves the right to change this Privacy Policy from time to time. We will post the revised
      Privacy Policy on this Site located at www.onehourlearning.com/privacy whenever we make any changes. We encourage
      you to review this Privacy Policy regularly for any changes. Your continued use of the Site and/or Services will be
      subject to the then-current Privacy Policy</p>
    <h4>10. Contact Us.</h4>
    <p>We welcome your comments or questions concerning our Privacy Policy. If you would like to contact Onehourlearning
      regarding this Privacy Policy, please contact us by emailing us at <span id="cloak87357"><a
          href="mailto:contact@onehourlearning.com">contact@onehourlearning.com</a></span>, or at:
    </p>
    <p>BENSAR LEARNING PVT. LTD.</p>
    <p>E43/1, OKHLA PHASE 2</p>
    <p>SPRINGBOARD BUILDING</p>
    <p>NEW DELHI 110020</p>
  </div>
  <app-footer></app-footer>