<app-header></app-header>

<div class="container jumbotron" style="margin-top: 120px;">
  <div class="row">
    <div class="col-12">
     <h3>
        <b>ONE OF THE LARGEST READYMADE CORPORATE COURSE LIBRARY IN THE WORLD: 300+ READYMADE COURSES (FULLY ANIMATED &
            INTERACTIVE) AND 250+ MANAGEMENT BOOK SUMMARIES.
          </b>
     </h3> 
      <div class="courses-list my-4">
        <ol>
          <li>In case you have your own LMS / 3rd party LMS, these courses can be hosted on your LMS. Courses are in
            SCORM1.2 format</li>
          <li>In case you don’t have an LMS, users can be registered on our portal / LMS-on-the-cloud and access courses
          </li>
        </ol>
      </div>

    </div>
    <div class="col-md-6">
      <h4 class="list-heading">
        STANDARD COURSES
        (Each course of 1-hour duration; 10-18 modules of 3-7 min each; Quiz & Certificate included)
      </h4>
      <div class="courses-list">
        <h4>LEADERSHIP COURSES </h4>
        <ol>
          <li>Leadership fundamentals</li>
          <li>First time manager</li>
          <li>Managing team</li>
          <li> Delegation skills</li>
          <li>Mentoring</li>
          <li> Performance appraisal</li>
          <li> Feedback essentials</li>
          <li> Leadership for middle managers</li>

        </ol>
      </div>
      <div class="courses-list">
        <h4>MANAGEMENT COURSES</h4>
        <ol>
          <li>Strategy in practice</li>
          <li>Decision making</li>
          <li>Negotiation skills</li>
          <li>Influencing skills</li>
          <li>Managing conflict</li>
          <li>Interviews Skills</li>
          <li>Analytical Intelligence</li>
          <li>Managing virtual teams</li>
          <li>Performance coaching</li>
        </ol>
      </div>

      <div class="courses-list">
        <h4>BEHAVIORAL COURSES</h4>
        <ol>
          <li>Emotional intelligence</li>
          <li>Interpersonal skills</li>
          <li>Winning attitude</li>
          <li>Resilience</li>
          <li>Intrapreneurship</li>
          <li> Change management</li>
        </ol>
      </div>


      <div class="courses-list">
        <h4>BUSINESS COURSES</h4>
        <ol>
          <li>Business communication</li>
          <li>Email skills</li>
          <li>Communication skills</li>
          <li>Corporate etiquettes</li>
          <li>Creativity & innovation</li>
          <li>Problem solving</li>
          <li>Stakeholder management</li>
        </ol>
      </div>


      <div class="courses-list">
        <h4>PRODUCTIVITY COURSES</h4>
        <ol>
          <li>Personal effectiveness</li>
          <li>Stress management</li>
          <li>Work-life balance</li>
          <li>Time management</li>
        </ol>
      </div>
    </div>
    <div class="col-md-6">
      <div class="courses-list">
        <h4>COMPLIANCE COURSES</h4>
        <ol>
          <li>Prevention of sexual harassment (POSH)</li>
          <li>Code of conduct (COC)</li>
          <li>Diversity & Inclusion</li>
        </ol>
      </div>

      <div class="courses-list">
        <h4>WORKPLACE ESSENTIALS</h4>
        <ol>
          <li>Excel - Basic</li>
          <li>Excel - Intermediate
          </li>
          <li>Excel - Advanced</li>
          <li>MS PPT</li>
          <li>Business PPT</li>
          <li>Power BI – comprehensive course – 6 hours</li>
        </ol>
      </div>


      <div class="courses-list">
        <h4>SALES & MARKETING</h4>
        <ol>
          <li>Enterprise sales</li>
          <li>Marketing essentials
          </li>
          <li>Marketing research</li>
          <li>Digital marketing</li>
          <li>Brand management</li>
          <li>PR (Public Relations)</li>
          <li>Customer service essentials
          </li>
        </ol>
      </div>



      <div class="courses-list">
        <h4>
          FUNCTIONAL COURSES
        </h4>
        <ol>
          <li>HR essentials</li>
          <li>Lean sigma
          </li>
          <li>Project management</li>
          <li>Supply chain management</li>
          <li>Business statistics</li>
          <li>Advanced analytics</li>
          <li>Cyber security</li>
          <li>Mechanical, Electrical, Plumbing</li>
        </ol>
      </div>

      <div class="courses-list">
        <h4>
          FINANCE COURSES
        </h4>
        <ol>
          <li>Finance for non-finance</li>
          <li>Balance sheet in detail
          </li>
          <li>P&L in detail
          </li>
          <li>Finance ratios</li>
          <li>Wealth management</li>
          <li>Financial literacy</li>

        </ol>
      </div>



    </div>
  </div>
</div>

<div class="container jumbotron">
  <div class="row">

    <div class="col-md-6">
      <div class="courses-list">
        <h4>UPSKILLING COURSES:</h4>
        <ol>
          <li>Spoken English – Basic – 3 months course - 5 min daily learning</li>
          <li>Spoken English – Advanced – 6 months course – 5-10 min daily learning</li>
          <li>Complete financial accounting course – Foundation – 60 hours</li>
          <li>Complete financial accounting course – Advanced – 150 hours</li>
        </ol>
      </div>
      <h4 class="list-heading">
        SPRINT COURSES
        (Each course of ~ 20-25 min duration; 5-10 modules of 2-4 min each; Quiz & Certificate included)
      </h4>
      <div class="courses-list">
        <h4>LEADERSHIP COURSES</h4>
        <ol>
          <li>Leadership styles</li>
          <li>Performance coaching</li>
          <li>Team management</li>
          <li>Team coaching & motivation</li>
          <li>First time manager</li>
          <li>Delegation skills</li>
          <li>Mentoring for mentors</li>
          <li>Getting mentored (mentees)</li>
          <li>Managing virtual teams</li>
          <li>Leading with empathy</li>
        </ol>
      </div>


      <div class="courses-list">
        <h4>MANAGEMENT COURSES </h4>
        <ol>
          <li>Negotiation skills</li>
          <li>Influencing skills</li>
          <li>Organizational change</li>
          <li>Interview questions</li>
          <li>Analytical Intelligence </li>
          <li>Executive presence</li>
          <li>7 steps analysis process</li>
          <li>Feedback essentials</li>
          <li>Crucial conversations</li>
          <li>Performance appraisal</li>

        </ol>
      </div>

      <div class="courses-list">
        <h4>BEHAVIORAL COURSES</h4>
        <ol>
          <li>Emotional intelligence</li>
          <li>Self-awareness and self-management </li>
          <li>Interpersonal skills</li>
          <li>The right attitude
          </li>
          <li>Changing oneself
          </li>
          <li>Risk management for managers
          </li>
          <li>Taking initiatives
          </li>
          <li>Ownership mindset
          </li>
          <li>Change for leaders
          </li>
          <li>Persuading people
          </li>

        </ol>
      </div>

      <div class="courses-list">
        <h4>BUSINESS COURSES
        </h4>
        <ol>
          <li>Business communication
          </li>
          <li>Email skills
          </li>

          <li>Communication skills
          </li>

          <li> Body language
          </li>

          <li>Corporate etiquettes
          </li>

          <li>Creativity for managers
          </li>

          <li>Innovation mindset
          </li>
          <li>Assertiveness

          </li>
          <li>Customer centricity
          </li>

          <li>Executive presence
          </li>

        </ol>
      </div>
    </div>
    <div class="col-md-6">

      <div class="courses-list">
        <h4>PRODUCTIVITY COURSES
        </h4>
        <ol>
          <li>Personal effectiveness
          </li>
          <li>Time management
          </li>

          <li>Managing procrastination</li>

          <li>Planning & organizing</li>

          <li> Creativity & innovation
          </li>

          <li> Decision making
          </li>

          <li>Personal decision making</li>

          <li>Tools for decision making</li>
          <li> Working from home
          </li>
          <li>Execution
          </li>
        </ol>
      </div>



      <div class="courses-list">
        <h4>WELLNESS COURSES
        </h4>
        <ol>
          <li>Stress management
          </li>

          <li>Calming the mind</li>

          <li>Avoiding burnout</li>

          <li>Work-life balance
          </li>

          <li>Wellness in the workplace
          </li>

          <li>Happiness at work</li>

          <li>Stimulation & learning</li>
          <li> Handling criticism
          </li>
          <li>Self-awareness & self-regulation

          </li>
        </ol>
      </div>



      <div class="courses-list">
        <h4>HUMAN VALUES
        </h4>
        <ol>
          <li>Business ethics
          </li>

          <li>Unconscious bias</li>

          <li>Building working relationships
          </li>

          <li>Collaboration</li>
        </ol>
      </div>



      <div class="courses-list">
        <h4>NEW LEARNINGS
        </h4>
        <ol>
          <li>Cognitive flexibility
          </li>

          <li>How to learn</li>

          <li>Design thinking
          </li>

          <li>Critical thinking</li>
        </ol>
      </div>


      <div class="courses-list">
        <h4>COMPLIANCE COURSES
        </h4>
        <ol>
          <li> Prevention of sexual harassment (POSH)
          </li>

          <li> Information security</li>

          <li>Fire safety in buildings
          </li>

          <li>Fire safety in factory</li>
          <li>EHS (Environment, health & safety)</li>
          <li>Anti-money laundering</li>
          <li>Insider trading</li>
          <li>Code of conduct (COC)</li>
          <li>Diversity & inclusion</li>
        </ol>
      </div>

    </div>
  </div>
</div>

<div class="container jumbotron">
  <div class="row">

    <div class="col-md-6">
      <div class="courses-list">
        <h4>WORKPLACE ESSENTIALS
        </h4>
        <ol>
          <li> Executive presence
          </li>

          <li>Presentation skills </li>

          <li>Graphs, charts & data presentation
          </li>

          <li>Business PPT essentials
          </li>
          <li>Sales presentation</li>
          <li>Sales presentation</li>
          <li>Problem solving</li>
          <li>Goal setting
          </li>
          <li>Co-ordinating with others</li>
        </ol>
      </div>
      <h4 class="list-heading">
        MODULAR COURSES (~ 5-7 MIN)
        1 module of ~5-7 min each; No quiz
      </h4>
      <div class="courses-list">
        <h4>LEADERSHIP
        </h4>
        <ol>
          <li>Becoming successful leader
          </li>

          <li> Leadership styles</li>

          <li>Situational leadership
          </li>

          <li>Team coaching
          </li>
          <li>Team motivation</li>
          <li>Fairness & camaraderie</li>
          <li>Delegation vs. micro-management</li>
          <li>Reviewing performance</li>
        </ol>
      </div>


      <div class="courses-list">
        <h4>DELEGATION

        </h4>
        <ol>
          <li>Why to delegate</li>
          <li>How to delegate</li>
          <li>When to delegate</li>
          <li>Whom to delegate</li>
          <li>What to delegate</li>
          <li>Levels of delegation</li>
          <li>Manage being delegated to</li>
          <li>Delegation Do’s and Don’ts</li>
          <li>Delegation process</li>
        </ol>
      </div>

      <div class="courses-list">
        <h4>DECISION SKILLS

        </h4>
        <ol>
          <li> Decision making tools</li>
          <li> Decision making styles</li>
          <li> Group decision making</li>
          <li> Delphi method</li>
          <li> Personal decision making</li>
          <li> Making hard decisions</li>
          <li> Six thinking hats</li>
          <li> Ladder of inference</li>
        </ol>
      </div>
      <div class="courses-list">
        <h4>FEEDBACK SKILLS</h4>
        <ol>
          <li> Feedback process</li>
          <li> Positive & negative feedback</li>
          <li> Feedback – Do’s and Don’ts</li>
          <li> Managing negative reaction</li>
          <li> Feedback to stars</li>
          <li> Responding to feedback</li>
          <li> Receiving feedback</li>
          <li> Feed-forward</li>
        </ol>
      </div>
      <div class="courses-list">
        <h4>MANAGING CONFLICT
        </h4>
        <ol>
          <li> Reasons for conflict</li>
          <li> Conflict process</li>
          <li> Conflict management styles</li>
          <li> Conflict resolution</li>
          <li> Managing conflict as a Manager</li>
        </ol>
      </div>



      <div class="courses-list">
        <h4>GOAL SETTING
        </h4>
        <ol>
          <li> SMART goals</li>
          <li> Process of goal setting</li>
        </ol>
      </div>

      <div class="courses-list">
        <h4>PROBLEM SOLVING
        </h4>
        <ol>
          <li> Problem solving model</li>
          <li> Problem analysis method</li>
          <li> Barriers to problem solving</li>
          <li> Mind traps</li>
          <li> Process traps</li>
          <li> Types of intelligence</li>
        </ol>
      </div>

      <div class="courses-list">
        <h4>WINNING ATTITUDE
        </h4>
        <ol>
          <li> Attitude</li>
          <li> Interpersonal skills</li>
          <li> Health </li>
          <li> Humor</li>
        </ol>
      </div>

      <div class="courses-list">
        <h4>WORK-LIFE BALANCE
        </h4>
        <ol>

          <li> Achievement & enjoyment</li>>
          <li> Ambition & contentment</li>
          <li> Stimulation & learning</li>
          <li> Responsibility & security</li>
          <li> Change & wellness</li>

        </ol>
      </div>


      <div class="courses-list">
        <h4>MANAGING CHANGE
        </h4>
        <ol>

          <li> Human brain & change</li>
          <li> Changing oneself</li>
          <li> Leading change</li>
          <li> Change for leaders</li>
          <li> Kotter’s 8 steps to change</li>


        </ol>
      </div>



      <div class="courses-list">
        <h4>MANAGING TEAM
        </h4>
        <ol>
          <li> Team formation</li>
          <li> Team management styles</li>
          <li> Team dysfunctions</li>

        </ol>
      </div>

      <div class="courses-list">
        <h4>MANAGING STRESS
        </h4>
        <ol>
          <li> Types of stress</li>
          <li> Burnout</li>
          <li> Calming the mind</li>

        </ol>
      </div>

      <div class="courses-list">
        <h4>INTERPERSONAL SKILLS
        </h4>
        <ol>
          <li> Talking to others</li>
          <li> Remembering names</li>
          <li> Persuading people</li>
          <li> Handling conflicts</li>
          <li> Managing criticism</li>

        </ol>
      </div>
      
      <div class="courses-list">
        <h4>INTREPRENEURSHIP
        </h4>
        <ol>
          <li> Intrapreneurial grid</li>
          <li> Intrapreneurial manager</li>
          <li> Risk-benefit analysis</li>
          <li> Success measurement</li>
        </ol>
      </div>
    </div>
    <div class="col-md-6">
        <div class="courses-list">
            <h4>COMMUNICATION SKILLS
            </h4>
            <ol>
              <li> Communication enablers & barriers</li>
              <li> Communication process</li>
              <li> Better listening</li>
              <li> Body language</li>
            </ol>
          </div>
    
          <div class="courses-list">
            <h4>BUSINESS COMMUNICATION
            </h4>
            <ol>
              <li> Business meeting skills</li>
              <li> Business email skills</li>
              <li> Business phone skills</li>
              <li> Conference call etiquettes</li>
              <li> Instant messenger usage</li>
            </ol>
          </div>
    
          <div class="courses-list">
            <h4>PERSONAL EFFECTIVENESS
            </h4>
            <ol>
              <li> Self-confidence</li>
              <li> Personal management</li>
              <li> Managing emotions</li>
              <li> Communication effectiveness</li>
              <li> Assertiveness</li>
            </ol>
          </div>
    
          <div class="courses-list">
            <h4>MANAGING TIME
            </h4>
            <ol>
              <li> Planning your time</li>
              <li> Prioritizing your tasks</li>
              <li> Schedule planning</li>
              <li> Managing distractions</li>
              <li> Procrastination</li>
              <li> Using leverage</li>
              <li> Getting more out of day</li>
            </ol>
          </div>
    
    
          <div class="courses-list">
            <h4>INFLUENCING PEOPLE
            </h4>
            <ol>
              <li> Principle of justification & contrast</li>
              <li> Reject, retreat, repeat</li>
              <li> Reciprocity & scarcity</li>
              <li> Social validation</li>
              <li> Commitment & consistency</li>
              <li> Principle of authority</li>
            </ol>
          </div>
    
          <div class="courses-list">
            <h4>FIRST TIME MANAGER
            </h4>
            <ol>
              <li> Managerial transition</li>
              <li> FTM – Good practices</li>
              <li> Managing tough situations</li>
              <li> Managing difficult team members</li>
              <li> Managing difficult scenarios</li>
              <li> FTM – Traps to avoid</li>
              <li> Managerial grid</li>
            </ol>
          </div>
          <div class="courses-list">
            <h4>CREATIVITY & INNOVATION
            </h4>
            <ol>
              <li> Lateral thinking</li>
              <li> Barriers to creativity</li>
              <li> Becoming creativity</li>
              <li> Becoming innovative</li>
    
            </ol>
          </div>
          <div class="courses-list">
            <h4>OFFICE ETIQUETTES
            </h4>
            <ol>
              <li> Dressing etiquette</li>
              <li> Communication etiquette</li>
              <li> Business etiquette</li>
              <li> Office & phone etiquette</li>
              <li> Meeting & dining etiquette</li>
              <li> Time etiquette</li>
              <li> Email etiquette</li>
    
            </ol>
          </div>
    
          <div class="courses-list">
            <h4>TEAM APPRAISAL
            </h4>
            <ol>
              <li> Managing appraisal bias</li>
              <li> Appraisal feedback</li>
              <li> Managing poor performance</li>
              <li> Managing emotional situations</li>
    
            </ol>
          </div>
    
          <div class="courses-list">
            <h4>INTERVIEW SKILLS
            </h4>
            <ol>
              <li> Interview preparation</li>
              <li> Behavioral questions</li>
              <li> Framing questions</li>
              <li> Observing behavior</li>
              <li> Interview rating</li>
              <li> Managing interview bias</li>
            </ol>
          </div>
    
          <div class="courses-list">
            <h4>CODE OF CONDUCT
            </h4>
            <ol>
              <li> Confidentiality</li>
              <li> Intellectual property</li>
              <li> Media & social media</li>
              <li> Gifts & business courtesies</li>
              <li> Conflict of interest</li>
              <li> Anti-bribery</li>
              <li> Political & religious affiliations</li>
              <li> Equal opportunity</li>
              <li> Workplace harassment</li>
            </ol>
          </div>
    
    
          <div class="courses-list">
            <h4>CRITICAL THINKING
            </h4>
            <ol>
              <li> How to think critically</li>
              <li> Critical thinking steps</li>
              <li> Critical thinking skills</li>
              <li> Dealing with mistakes</li>
    
            </ol>
          </div>
    
          <div class="courses-list">
            <h4>ENTERPRISE SALES
            </h4>
            <ol>
              <li>Situation questions</li>
              <li> Problem questions</li>
              <li> Implication questions</li>
              <li> Need pay-off questions</li>
    
            </ol>
          </div>
    </div>
  </div>
</div>


<app-footer></app-footer>