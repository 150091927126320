import { Routes } from '@angular/router';
import { AfterLoginService } from './core/services/after-login.service';
import { BeforeLoginService } from './core/services/before-login.service';
import { FullComponent } from './layouts/full/full.component';
import { environment } from '../environments/environment';
import { AppBlankComponent } from './layouts/blank/blank.component';
export const AppRoutes: Routes = [
    {
        path: '',
        component: FullComponent,
        children: [
            {
                path: 'admin',
                canActivate: [AfterLoginService],
                data: { role: [environment.USER_ROLES.SUPER_ADMIN, environment.USER_ROLES.ADMIN] },
                loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
            },
            {
                path: 'group-admin', 
                canActivate: [AfterLoginService], 
                data: { role: [environment.USER_ROLES.GROUP_ADMIN] },
                loadChildren: () => import('./modules/group-admin/group-admin.module').then(m => m.GroupAdminModule),
            },
            {
                path: 'user', canActivate: [AfterLoginService], data: { role: [environment.USER_ROLES.USER] },
                loadChildren: () => import('./modules/group-admin/group-admin.module').then(m => m.GroupAdminModule),
            },
            {
                path: 'root-admin', canActivate: [AfterLoginService], data: { role: [environment.USER_ROLES.ROOT_ADMIN] },
                loadChildren: () => import('./modules/root-admin/root-admin.module').then(m => m.RootAdminModule),
            },
        ]
    },
    {
        path: '',
        component: AppBlankComponent,
        children: [
            {
                path: 'public',
                // canActivate: [BeforeLoginService],
                loadChildren: () => import('./modules/public/public.module').then(m => m.PublicModule),
            },
        ]
    },
];
