<div class="Main" style="padding-top: 60px; background-color: #ffff !important">
  <app-header></app-header>
  <div>
    <mat-card>
      <mat-card-content>
        <div class="main">
          <mat-icon class="icon-style">business</mat-icon>
          <div class="content">
            <div>
              <h2 mat-title class="content-title">Contact Us</h2>
            </div>
            <div class="sub-main">
              <div mat-body>Mail us at: learn@onehourlearning.com</div>
              <!-- <div mat-body>Nishant Kumar: nishant@onehourlearning.com</div>
              <div mat-body>Sujith Maroli: maroli@onehourlearning.com</div> -->
            </div>
          </div>
        </div>

        <!-- <div class="main">
          <mat-icon class="icon-style">contact_phone</mat-icon>
          <div class="content">
            <div>
              <h2 mat-title class="content-title">Call us at</h2>
            </div>
            <div class="sub-main">
              <div mat-body>Delhi NCR: +91-9212100436</div>
              <div mat-body>Mumbai: +91-8451046147</div>
              <div mat-body>Bengaluru: +91-9866011312</div>
              <div mat-body>Chennai: +91-9137866823</div>
            </div>
          </div>
        </div>
        <div class="main">
          <mat-icon class="icon-style">apartment </mat-icon>
          <div class="content">
            <h2 mat-title class="content-title">Delhi office address:</h2>
            <div class="sub-main">
              <div mat-body>Bensar Learning Pvt. Ltd.</div>
              <div mat-body>91 Springboard building</div>
              <div mat-body>+91-9866011312</div>
              <div mat-body>New Delhi 110020</div>
            </div>
          </div>
        </div> -->
      </mat-card-content>
    </mat-card>
  </div>
</div>
