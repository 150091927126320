
<app-header></app-header>
<div class="container jumbotron d-flex flex-column" style="line-height: 40px; margin-top: 120px;">
    <h1 class="text-secondary">Credits</h1>
    <a href="https://www.freepik.com/free-photo/illuminated-crumpled-yellow-paper-light-bulb-idea-white-background_2983070.htm">Bulb Background photo created by freepik - www.freepik.com</a>

    <a href="https://www.freepik.com/free-photos-vectors/background">Background photo created by freepik - www.freepik.com</a>
    
     
    
    <a href="https://www.freepik.com/free-photos-vectors/school">School vector created by macrovector - www.freepik.com</a>
    
     
    
    <a href="https://www.freepik.com/free-photos-vectors/business">Business photo created by yanalya - www.freepik.com</a>
    
     
    
    <a href="https://www.freepik.com/free-photos-vectors/school">School vector created by freepik - www.freepik.com</a>
</div>

<app-footer></app-footer>