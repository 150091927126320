<div class="login-register" style="background-image:url(assets/images/login-register.jpg);">
<div class="login-register-box">
    <mat-card>
        <mat-card-content>
            <form [formGroup]="form" (ngSubmit)="loginSubmit()">
                <div class="text-center">
                    <img alt="homepage" src="{{src}}" width="150" class="m-b-20">
            <!-- <img src="../../../../../assets/images/logo-light-icon.png" alt="logo"> -->
                    <h4 class="m-t-0">Login to your account</h4>
                </div>

                <div fxLayout="row wrap">
                    <!-- col full-->
                    <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                        <mat-form-field>
                            <input matInput placeholder="E-mail" formControlName="email">
                        </mat-form-field>
                        <small *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched"
                            class="text-danger support-text">{{validationMessage.emailRequired}}</small>
                    </div>
                    <!-- col full-->
                    <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                        <mat-form-field>
                            <input matInput type="password" placeholder="Password" formControlName="password">
                        </mat-form-field>
                        <small
                            *ngIf="form.controls['password'].hasError('required') && form.controls['password'].touched"
                            class="text-danger support-text">{{validationMessage.passwordRequired}}</small>
                    </div>
                    <!-- col half-->
                    <!-- <div fxFlex.gt-sm="50" fxFlex.gt-xs="50">
                            <mat-checkbox color="warn">Remember me</mat-checkbox>
                        </div> -->
                    <!-- col half-->
                    <div fxFlex.gt-sm="50" fxFlex.gt-xs="50" class="text-right">
                        <!-- <a [routerLink]="['/authentication/forgot']" class="link">Forgot pwd?</a> -->
                    </div>
                    <!-- col full-->
                    <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                        <button mat-raised-button color="primary" class="btn-block btn-lg m-t-20 m-b-20" type="submit"
                            [disabled]="!form.valid">Login</button>
                    </div>
                    <!-- col full-->
                    <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100" class="text-center">
                        <span>
                            <a [routerLink]="['/public/forgot-password']" class="text-info link">Reset Password</a>
                        </span>
                    </div>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>
</div>