<app-header></app-header>
<div class="course-packages">
  <div class="home-banner">
    <div class="img-box">
      <img src="../../../../../../../assets/img/teams.png" alt="teams" />
    </div>
    <div class="right-text">
      <h2 style="padding-left: 15px">
        Are you a team leader or people <br />
        manager looking for effective <br />
        training materials to develop your team?
      </h2>
    </div>
  </div>
</div>
<div class="select-course-package">
  <div fxLayout="row wrap" fxLayoutGap="31px" fxLayoutAlign="center none">
    <div
      class="course-card"
      fxFlex.gt-sm="25"
      fxFlex="100"
      *ngFor="let item of activeSubscriptionList"
    >
      <div class="card">
        <div class="card-header">
          <div class="img-box">
            <div class="text-over">
              <span>{{ item.name }}</span>
              <div class="download" (click)="download(item.id)">
                <span> Click to download the course list </span>
                <span *ngIf="item.name === 'Book Summaries'">
                  Click to download the book list
                </span>
                <!-- <span *ngIf="item.name === 'Spoken English Course'">
                  Click to download the Course details
                </span> -->
              </div>
            </div>
            <img src="{{ item.banner }}" alt="course" />
          </div>
          <div class="coming-soon" *ngIf="item.name === 'Book Summaries'">
            <img src="../../../../../../../assets/img/coming-soon.png" alt="" />
          </div>
        </div>
        <!-- <img src=".../../../../../../../../../assets/img/course-select.png" alt="course"> -->
      </div>
      <!-- <div class="coming-soon" *ngIf="data == 2">
          <img src="../../../../../../../assets/img/coming-soon.png" alt="">
        </div> -->
    </div>
  </div>
</div>

<div class="custom-courses-packages">
  <div class="heading">
    <h2>Get started with the custom courses packages</h2>
  </div>
  <div fxLayout="row wrap" fxLayoutGap="40px">
    <div fxFlex.gt-sm="50" fxFlex="100">
      <form class="mt-5" [formGroup]="form">
        <div class="input-hint">
          Enter the number of users (enter number greater than 10 and less than
          500). For 500 and above users contact us
        </div>
        <mat-form-field appearance="outline">
          <input
            type="number"
            matInput
            formControlName="userCount"
            (change)="onChange()"
          />
          <small
            *ngIf="
              form.controls['userCount'].hasError('required') &&
              form.controls['userCount'].touched
            "
            class="text-danger support-text"
            >Enter users count</small
          >
          <small
            *ngIf="
              form.controls['userCount'].hasError('max') &&
              form.controls['userCount'].touched
            "
            class="text-danger support-text"
            >Enter users count less than 500</small
          >
        </mat-form-field>
        <div class="input-hint">Select the course packages</div>
        <ng-container *ngIf="activeCoursePackages.length >= 1">
          <ng-multiselect-dropdown
            appearance="outline"
            [settings]="statusSettings"
            [(ngModel)]="selectedItems"
            formControlName="coursePackage"
            [data]="activeCoursePackages"
            [placeholder]="'Select package(s)'"
            (onDeSelect)="onItemDeSelect($event)"
            (onSelect)="onItemSelect($event)"
            (onSelectAll)="onSelectAll($event)"
            (onDeSelectAll)="onDeSelectAll($event)"
          >
          </ng-multiselect-dropdown>
        </ng-container>
        <!-- <div *ngIf = "showProperty === true" class="input-hint">Select the number of Management courses</div>
        <mat-form-field  *ngIf = "showProperty === true" appearance="outline">
          <mat-select type = "number" formControlName="courseCount" [(ngModel)]="selectedCourses" (selectionChange)="setPrice()">
            <mat-option value=10>10</mat-option>
            <mat-option value=25>25</mat-option>
            <mat-option value=50>50</mat-option>
            <mat-option value=100>100+</mat-option>
          </mat-select>
        </mat-form-field> -->
        <div *ngIf="showProperty === true" class="input-hint">
          Select the validity period
        </div>
        <mat-form-field *ngIf="showProperty === true" appearance="outline">
          <mat-select
            formControlName="coursePeriod"
            [(ngModel)]="selectedPeriod"
            (selectionChange)="setPrice($event)"
          >
            <!-- <mat-option value="1 Month">1 Month</mat-option> -->
            <mat-option value="3 Months">3 Months</mat-option>
            <mat-option value="6 Months">6 Months</mat-option>
            <mat-option value="1 Year">1 Year</mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>
    <div fxFlex.gt-sm="45" fxFlex="100">
      <div class="mt-5">
        <div class="payment-info">
          <div>
            <span class="m-b-10 m-t-10">Your total payment will be</span>
            <div fxLayout="row wrap" fxLayoutAlign="space-between center">
              <h2 class="price-text">
                {{ totalPrice | number: "1.0":"en-US" }}
              </h2>
              <h3>INR</h3>
            </div>
            <div
              fxLayout="row"
              fxLayoutAlign="space-between none"
              class="date-validity m-b-15"
            >
              <div>
                <span class="font-12">Users</span><br />
                <span class="text-black">{{ form.value.userCount }}</span>
              </div>
              <div>
                <span class="font-12">Validity</span><br />
                <span class="text-black">{{ form.value.coursePeriod }}</span>
              </div>
            </div>
          </div>
          <button mat-flat-button class="get-started" (click)="getStarted()">
            Get Started
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
